import React from "react";
import Footer from "../Global/Footer";
import Sidebar from "../Global/Sidebaar";

import ServicesRedirectButtons from "../ServicesAllTabs/ServicesRedirectButtons";

const RegisterForeignCompanies = () => {
  return (
    <>
      <Sidebar />
      <div className="maindiv-register-foreign-comp">
        <div className="subdivregister-foreign-comp">
          <ServicesRedirectButtons />

          <div className="register-foreign-comp-content">
            <div className="register-foreign">
            <h1>REGISTRATION OF FOREIGN COMPANIES</h1>
            </div>
            <p>
              Companies (Registration of Foreign Companies) Rules, 2014
              prescribes guidelines to be followed for the registration of a
              foreign company.
            </p>

            <h2>
              Particulars relating to directors and Secretary to be furnished to
              the Registrar by foreign Companies:
            </h2>

            <p>
              1. Every foreign company shall, within thirty days of
              establishment of its place of business in India, in addition to
              the particulars specified in subsection (1) of section 380 of the
              Act (i.e, Companies Act, 2013), also deliver to the Registrar for
              registration, a list of directors and Secretary of such company.
            </p>

            <p>
              2. The list of directors and secretary or equivalent (by whatever
              name called) of the foreign company shall contain the prescribed
              particulars, for each of the persons included in such list.
            </p>

            <p>
              3. (3) A foreign company shall, within a period of thirty days of
              the establishment of its place of business in India, file with the
              registrar Form FC-1 with such fee as provided in Companies
              (Registration Offices and Fees) Rules, 2014 and with the documents
              required to be delivered for registration by a foreign company in
              accordance with the provisions of sub-section (1) of section 380
              and the application shall also be supported with an attested copy
              of approval from the Reserve Bank of India under Foreign Exchange
              Management Act or Regulations, and also from other regulators, if
              any, approval is required by such foreign company to establish a
              place of business in India or a declaration from the authorized
              representative of such foreign company that no such approval is
              required.
            </p>

            <p>
              3. (3) A foreign company shall, within a period of thirty days of
              the establishment of its place of business in India, file with the
              registrar Form FC-1 with such fee as provided in Companies
              (Registration Offices and Fees) Rules, 2014 and with the documents
              required to be delivered for registration by a foreign company in
              accordance with the provisions of sub-section (1) of section 380
              and the application shall also be supported with an attested copy
              of approval from the Reserve Bank of India under Foreign Exchange
              Management Act or Regulations, and also from other regulators, if
              any, approval is required by such foreign company to establish a
              place of business in India or a declaration from the authorized
              representative of such foreign company that no such approval is
              required.
            </p>

            <h2>Financial Statement of foreign company:</h2>
            <p>
              Every foreign company shall prepare financial statement of its
              Indian business operations in accordance with Schedule III or as
              near thereto as may be possible for each financial year including-
            </p>

            <p>
              {"\u2022"} i. documents required to be annexed thereto in
              accordance with the provisions of Chapter IX of the Act i.e.
              Accounts of Companies
            </p>
            <p>
              {"\u2022"} ii. documents relating to copies of latest consolidated
              financial statements of the parent foreign company , as submitted
              by it to the prescribed authority in the country of its
              incorporation under the provisions of the law for the time being
              in force in that country:
              <br />
              Provided that where such documents are not in English language,
              there shall be annexed to it a certified translation thereof in
              the English language:
            </p>

            <p>
              {"\u2022"} iii. Such other documents as may be required to be
              annexed or attached in accordance with sub-rule (2).
            </p>

            <p>
              2. Every foreign company shall, along with the financial statement
              required to be filed with the Registrar, attach thereto the
              following documents; namely:-
            </p>

            <p>
              a. Statement of related party transaction, which shall include-
            </p>

            <p>
              {"\u2022"} i. (i) name of the person in India which shall be
              deemed to be the related party within the meaning of clause (76)
              of section 2 of the Act of the foreign company or of any
              subsidiary or holding company of such foreign company or of any
              firm in which such foreign company or its subsidiary or holding
              company is a partner;
            </p>
            <p>{"\u2022"} ii. nature of such relationship;</p>
            <p>{"\u2022"} iii. description and nature of transaction;</p>
            <p>
              {"\u2022"} iv. amount of such transaction during the year with
              opening ,closing, highest and lowest balance during the year and
              provisions made (if any) in respect of such transactions;
            </p>

            <p>{"\u2022"} iv. reason of such transaction;</p>
            <p>
              {"\u2022"} vi. material effect of such transaction on both the
              parties;
            </p>
            <p>
              {"\u2022"} vii. amount written off or written back in respect of
              dues from or to the related parties;
            </p>
            <p>
              {"\u2022"} viii. a declaration that such transactions were carried
              out at arms’ length basis; and
            </p>
            <p>
              {"\u2022"} ix. any other details of the transaction necessary to
              understand the financial impact;
            </p>

            <p>
              <strong>
                b. Statement of repatriation of profits which shall include-
              </strong>
            </p>

            <p>{"\u2022"} i. amount of profits repatriated during the year;</p>
            <p>{"\u2022"} ii. recipients of the repatriation;</p>
            <p>{"\u2022"} iii. form of repatriation;</p>
            <p>{"\u2022"} iv. dates of repatriation;</p>
            <p>
              {"\u2022"} v. details if repatriation made to a jurisdiction other
              than the residence of the beneficiary;
            </p>
            <p>{"\u2022"} vi. mode of repatriation; and</p>
            <p>
              {"\u2022"} vii. approval of the Reserve Bank of India or any other
              authority, if any.
            </p>

            <p>
              <strong>
                c. Statement of transfer of funds (including dividends if any)
                which shall, in relation of any fund transfer between place of
                business of foreign company in India and any other related party
                of the foreign company outside India including its holding,
                subsidiary and associate company, include-
              </strong>
            </p>

            <p>{"\u2022"} i. date of such transfer;</p>
            <p>{"\u2022"} ii. amount of fund transferred or received;</p>
            <p>{"\u2022"} iii. mode of receipt or transfer of fund;</p>
            <p>{"\u2022"} iv. purpose of such receipt or transfer; and</p>
            <p>
              {"\u2022"} v. approval of Reserve Bank of India or any other
              authority, if any.
            </p>

            <p>
              3. The documents referred to in this rule shall be delivered to
              the Registrar{" "}
              <strong>
                within a period of six months of the close of the financial year
              </strong>{" "}
              of the foreign company to which the documents relate. Provided
              that the Registrar may, for any special reason, and on application
              made in writing by the foreign company concerned, extend the said
              period by a period not exceeding three months.
            </p>

            <h2>Audit of accounts of foreign company:</h2>
            <p>
              1. Every foreign company shall get its accounts, pertaining to the
              Indian business operations prepared in accordance with the
              requirements of clause (a) of sub-section (1) of section 381 and
              rule 4, audited by a practicing Chartered Accountant in India or a
              firm or limited liability partnership of practicing chartered
              accountants.
            </p>
            <p>
              2. The provisions of Chapter X i.e. Audit and Auditors and rules
              made there under, as far as applicable, shall apply, mutatis
              mutandis, to the foreign company.
            </p>

            <h2>List of places of business of foreign company:</h2>
            <p>
              Every foreign company shall file with the Registrar, along with
              the financial statement, in Form FC.3 with such fee as provided
              under Companies (Registration Offices and Fees) Rules, 2014 a list
              of all the places of business established by the foreign company
              in India as on the date of balance sheet.
            </p>

            <h2>Annual Return:</h2>
            <p>
              Every foreign company shall prepare and file, within a period of
              sixty days from the last day of its financial year, to the
              Registrar annual return in Form FC.4 along with such fee as
              provided in the Companies (Registration Offices and Fees) Rules,
              2014 containing the particulars as they stood on the close of the
              financial year.
            </p>

            <h2>
              Office where documents to be delivered and fee for registration of
              documents:
            </h2>
            <p>
              1. Any document which any foreign company is required to deliver
              to the Registrar shall be delivered to the Registrar having
              jurisdiction over New Delhi, and references to the Registrar in
              Chapter XXII of the Act i.e. Companies Incorporated outside India
              and these rules shall be construed accordingly.
            </p>
            <p>
              2. The fee to be paid to the Registrar for registering any
              document relating to a foreign company shall be such as provided
              in the Companies (Registration Offices and Fees) Rules, 2014.
            </p>
            <p>
              3. If any foreign company ceases to have a place of business in
              India, it shall forthwith give notice of the fact to the
              Registrar, and as from the date on which notice is so given, the
              obligation of the company to deliver any document to the Registrar
              shall cease, provided it has no other place of business in India.
            </p>

            <h2>Certification:</h2>
            <p>
              A copy of any charter, statutes, memorandum and articles, or other
              instrument constituting or defining the constitution of a Foreign
              company shall be duly certified to be a true copy in the manner
              given below:
            </p>
            <p>
              1. If the company is incorporated in a country outside the
              Commonwealth-
            </p>
            <p>a. the copy aforesaid shall be certified as a true copy by-</p>
            <p>
              {"\u2022"} i. (i) an official of the Government to whose custody
              the original is situated; or
              <br />
              {"\u2022"} ii. (ii) a Notary (Public) of such Country; or
              <br />
              {"\u2022"} iii. an officer of the company.
            </p>

            <p>
              b. The signature or seal of the official referred to in sub-clause
              (i) of clause (a) or the certificate of the Notary (Public)
              referred to in sub-clause (ii) of clause (a) shall be
              authenticated by a diplomatic or consular officer empowered in
              this behalf under section 3 of the Diplomatic and Consular
              Officers (Oaths and fees) Act, 1948 (XL of 1948), or where there
              is no such officer, by any of the officials mentioned in section 6
              of the Commissioners of Oath Act, 1889 (52 and 53 Vic. C. 10), or
              in any relevant Act for the said purpose.
            </p>
            <p>
              c. (c) The certificate of the officer of the company referred to
              in sub-clause (iii) of clause (a) shall be signed before a person
              having authority to administer an oath as provided under section 3
              of the Diplomatic and Consular Officers (Oath and Fees) Act, 1948
              (XL of 1948), or as the case may be, by section 3 of the
              Commissioners of Oath Act, 1889 (52 and 53 Vic, C. 10) and the
              status of the person administering the oath in the latter case
              being authenticated by any official specified in section 6 of the
              Commissioners of Oaths Act, 1889 (52 and 53 Vic. C. 10) or in any
              relevant Act for the said purpose.
            </p>
            <p>
              2. If the company is incorporated in any part of the Commonwealth,
              the copy of the document shall be certified as a true copy by-
            </p>
            <p>
              a. an official of the Government to whose custody the original of
              the document is committed; or
            </p>
            <p>b. a Notary (Public) in that part of the Commonwealth; or</p>
            <p>
              c. an officer of the company, on oath before a person having
              authority to administer an oath in that part of the Commonwealth.
            </p>
            <p>
              (3) Any altered document delivered to the Registrar should also be
              duly certified in the manner mentioned above.
            </p>
            <p>
              (4) If the Company is incorporated in a country falling outside
              the Commonwealth, but a party to the Hague Apostille Convention,
              1961-
            </p>
            <p>
              a. the copy of the documents shall be certified as a true copy by
              an official of the Government to whose custody the original is
              committed and be duly apostillised in accordance with Hague
              Convention;
            </p>
            <p>
              b. a list of the directors and the secretary of the Company, if
              any, the name and address of persons resident in India, authorized
              to accept notice on behalf of the Company shall be duly notarized
              and be apostillised in the Country of their origin in accordance
              with Hague Convention;
            </p>
            <p>
              c. the signatures and address on the Memorandum of Association and
              proof of identity, where required, of foreign nationals seeking to
              register a company in India shall be notarized before the notary
              of the country of their origin and be duly apostillised in
              accordance with the said Hague Convention.
            </p>

            <h2>Authentication of translated documents:</h2>
            <p>
              1. All the documents required to be filed with the Registrar by
              the foreign companies shall be in English language and where any
              such document is not in English language, there shall be attached
              a translation thereof in English language duly certified to be
              correct in the manner given in these rules.
            </p>
            <p>
              2. Where any such translation is made outside India, it shall be
              authenticated by the signature and the seal, if any, of-
            </p>
            <p>{"\u2022"} a. the official having custody of the original; or</p>
            <p>
              {"\u2022"} b. a Notary (Public) of the country (or part of the
              country) where the company is incorporated:
            </p>
            <p>
              Provided that where the company is incorporated in a country
              outside the Commonwealth, the signature or seal of the person so
              certifying shall be authenticated by a diplomatic or consular
              officer empowered in this behalf under section 3 of the Diplomatic
              and Consular Officers (Oaths and Fees) Act, 1948, or, where there
              is no such officer, by any of the officials mentioned in section
              6, of the Commissioners of Oaths Act, 1889 (52 and 53 Vic C 10),
              or in any relevant Act for the said purpose.
            </p>
            <p>
              3. Where such translation is made within India, it shall be
              authenticated by-
            </p>
            <p>
              {"\u2022"} a. an advocate, attorney or pleader entitled to appear
              before any High Court; or
            </p>
            <p>
              {"\u2022"} b. an affidavit, of a competent person having, in the
              opinion of the Registrar, an adequate knowledge of the language of
              the original and of English.
            </p>

            <h2>Documents to be annexed to prospectus:</h2>
            <p>
              The following documents shall be annexed to the prospectus,
              namely:-
            </p>
            <p>
              {"\u2022"} a. any consent to the issue of the prospectus required
              from any person as an expert;
            </p>
            <p>
              {"\u2022"} b. a copy of contracts for appointment of managing
              director or manager and in case of a contract not reduced into
              writing, a memorandum giving full particulars thereof;
            </p>
            <p>
              {"\u2022"} c. a copy of any other material contracts, not entered
              in the ordinary course of business, but entered within preceding
              two years;
            </p>
            <p>{"\u2022"} d. a copy of underwriting agreement; and</p>
            <p>
              {"\u2022"} e. a copy of power of attorney, if prospectus is signed
              through duly authorized agent of directors.
            </p>

            <h2>Action for improper use or description as foreign company:</h2>
            <p>
              If any person or persons trade or carry on business in any manner
              under any name or title or description as a foreign company
              registered under the Act or the rules made thereunder, that person
              or each of those persons shall, unless duly registered as foreign
              company under the Act and rules made thereunder, shall be liable
              for investigation under section 210 of the Act and action
              consequent upon that investigation shall be taken against that
              person.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default RegisterForeignCompanies;
