import { GiAchievement } from 'react-icons/gi';
import { FaStar, FaBusinessTime } from 'react-icons/fa';

const ourServicesData = [
    { title: "Statutory Audits", icon: <GiAchievement /> },
    { title: "Consultancy & Advisory", icon: <FaBusinessTime /> },
    { title: "Due Diligence Investigations", icon: <FaStar /> },
    { title: "VAT Audits", icon: <GiAchievement /> },
    { title: "Indirect Taxation", icon: <GiAchievement /> },
    { title: "Transfer Pricing", icon: <GiAchievement /> },
    { title: "Risk Management Services", icon: <GiAchievement /> },
    { title: "Business Advisory", icon: <GiAchievement /> },
    { title: "International Investor Services", icon: <GiAchievement /> },
    { title: "Corporate Restructuring, M & A", icon: <GiAchievement /> },
    { title: "Valuation", icon: <GiAchievement /> },
    { title: "Legal & Secretarial Services", icon: <GiAchievement /> },
    { title: "FEMA Consulting", icon: <GiAchievement /> },
    { title: "Advisory Services To NGO's", icon: <GiAchievement /> },
    { title: "IT - Services", icon: <GiAchievement /> },

]

export default ourServicesData;