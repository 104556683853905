import React from "react";
import Footer from "../Global/Footer";
import Sidebar from "../Global/Sidebaar";

import ServicesRedirectButtons from "../ServicesAllTabs/ServicesRedirectButtons";

const DirectTax = () => {
  return (
    <>
      <Sidebar />
      <div className="service-container">
        <div className="sub-container">
          <ServicesRedirectButtons />

          <div className="service-container-content">
            <div className="direct-taxation">
            <h1>DIRECT TAXATION</h1>
            </div>
            <h2>Direct Tax Consultancy</h2>
            <p>
              Direct tax consultancy together with innovative tax efficient
              strategies, provided by us form an integral part of viable
              business decisions. These help our clients attain the desired
              goals. We adopt a “result oriented approach” which is flexible and
              emphasizes delivery and value. It enhances the effect of
              commercially viable decisions and minimizes the tax burden.
            </p>

            <h2>Assessment (audit by tax authorities) and appeals</h2>
            <p>
              The assessment and appeals procedure under the Act involves the
              following:
            </p>

            <h2>Self-assessment</h2>
            <p>
              Every assessee, before submitting a tax return, is required to
              make a self-assessment of income and after taking into account the
              amount of tax already paid by way of TDS and advance tax, pays the
              balance tax (self-assessment tax) due on the income. Further,
              along with the tax, any interest arising on account of delay in
              furnishing of tax return or any default /deferment in payment of
              advance tax, is also required to be paid. The procedure for
              self-assessment and determination of tax liability has been
              depicted below by way of a flowchart.
            </p>

            <h2>WITHHOLDING TAX:-</h2>
            <h2> Withholding taxes on payments other than salaries</h2>
            <p>
              Under the Act, a person making certain specified payments such as
              salary, contractual payments, rent, interest, professional or
              technical fees, commission, payments to non-residents, etc. is
              required to withhold tax at source from such payments and comply
              with the associated requirements in respect of deposit of taxes in
              the Government treasury in the prescribed manner, issue of tax
              credit cerficates and filing withholding tax returns. The
              rationale of withholding tax is to provide a mechanism for tax
              authorities to collect tax at the source of income, and also to
              expand the tax base.
            </p>
            <p>
              Failure to comply with the prescribed withholding requirements
              could result in the levy of penal interest, penalty and
              prosecution under the Act, in addition to the liability to make up
              for the taxes not withheld/deposited.
            </p>
            <p>
              The following chart depicts the withholding tax provisions
              prescribed under the act:
            </p>
            <p>Some of the direct tax services provided by us include :</p>

            <h2>INCOME TAX & WITHHOLDING TAXES</h2>

            <ul className="bullet-points">
              <li>
                <p>{"\u2022"} Tax Management Services : successful tax compliance
</p>
              </li>
              <li>
                <p>{"\u2022"} Tax Appeals : Drafting and representing tax appeals before various appellate authorities like Commissioners/Appellate Tribunal.
</p>
              </li>
              <li>
                <p>{"\u2022"} Tax Planning : Advising clients on structuring tax-efficient business planning, including restructuring of business eg. Amalgamations/ merger etc.
</p>
              </li>
              <li>
                <p>{"\u2022"} Corporate Taxation Advisory Services : Advising large Companies / Corporations on their Income Tax matters, including Foreign taxation matters on Indian corporations.</p>
              </li>
              <li>
                <p>
                  {"\u2022"} Advising & reviewing of all necessary tax
                  withholding responsibilities.
                </p>
              </li>
              <li>
                <p>
                  {"\u2022"} Advising all financial subjects which are of your
                  interest and keeping you updated on the new amendments,
                  circulars, notifications & judgments.
                </p>
              </li>
              <li>
                <p>
                  {"\u2022"} Verification of all payments to vendors for the
                  purposes of determination of correct application of rates and
                  category for deduction of withholding taxes.
                </p>
              </li>
              <li>
                <p>
                  {"\u2022"} Computation of monthly TDS on the basis of above.
                </p>
              </li>
              <li>
                <p>
                  {"\u2022"} Monthly tax reconciliation of the TDS due and
                  deducted.
                </p>
              </li>
              <li>
                <p>
                  {"\u2022"} Services relating to Foreign Collaboration Agreements, NRIs taxation, Double Tax Avoidance Agreements Reliefs, Transfer Pricing etc.
                </p>
              </li>
              <li>
                <p>
                  {"\u2022"} Preparation and deposit of Monthly challans on or
                  before the statutory due dates.
                </p>
              </li>
              <li>
                <p>{"\u2022"} Filing of quarterly E-TDS Returns.</p>
              </li>
              <li>
                <p>{"\u2022"} Liaising with Senior Tax Counsels for obtaining legal opinions, conducting tax litigations ie. appeal court references and writ petitions etc.</p>
              </li>
              <li>
                <p>{"\u2022"} Filing annual Income Tax return.</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DirectTax;
