import React from "react";
import { Link } from "react-router-dom";

const Questions = () => {
  return (
    <>
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-16 mx-auto">
          <div className="flex flex-wrap -m-4">
            <div className="p-4 lg:w-1/3">
              <div className="h-full bg-lightBlue bg-opacity-75 px-8 pt-16 pb-24 rounded-lg overflow-hidden text-center text-white relative ques-content">
                <h1 className="title-font sm:text-2xl text-xl font-medium text-white mb-3 overflow-hidden">
                  Who we are ?
                </h1>
                <p className="leading-relaxed mb-3">
                We are prominent Chartered Accountants in India. We offer services in New Delhi and other major cities in India, like accounts outsourcing, auditing, company formation in India, Business taxation, corporate compliance, starting business in India, registration of foreign companies, taxation of expatriates etc.
                </p>
                <Link className="text-white inline-flex items-center learn-more">
                  Learn More
                  <svg
                    className="w-4 h-4 ml-2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M5 12h14"></path>
                    <path d="M12 5l7 7-7 7"></path>
                  </svg>
                </Link>
              </div>
            </div>
            <div className="p-4 lg:w-1/3">
              <div className="h-full bg-lightBlack bg-opacity-75 px-8 pt-16 pb-24 rounded-lg overflow-hidden text-center relative ques-content">
                <h1 className="title-font text-white sm:text-2xl text-xl font-medium mb-3 overflow-hidden">
                  Why choose us ?
                </h1>
                <p className="leading-relaxed text-white mb-3">
                We are team of experienced professionals who add value to their business and help them.<br />
                <div className="text-left">
                  Our clients can expect:<br />
                  ■ A fresh and proactive approach to their accounts and tax planning.<br />
                  ■ A friendly and personal service.<br />
                  ■ Innovative solutions to requirements.
                </div>
                </p>
                <Link className="text-white inline-flex items-center learn-more">
                  Learn More
                  <svg
                    className="w-4 h-4 ml-2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M5 12h14"></path>
                    <path d="M12 5l7 7-7 7"></path>
                  </svg>
                </Link>
              </div>
            </div>
            <div className="p-4 lg:w-1/3">
              <div className="h-full bg-green bg-opacity-75 px-8 pt-16 pb-24 rounded-lg overflow-hidden text-center relative ques-content">
                <h1 className="title-font text-white sm:text-2xl text-xl font-mediu mb-3 overflow-hidden">
                  How we are different ?
                </h1>
                <p className="text-white leading-relaxed mb-3">
                Our firm of chartered accountants represents a coalition of specialized skills that is geared to offer sound financial solutions and advices. The organization is a congregation of professionally qualified and experienced persons who are committed to add value and optimize the benefits accruing to clients.
                </p>
                <Link className="text-white inline-flex items-center learn-more">
                  Learn More
                  <svg
                    className="w-4 h-4 ml-2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M5 12h14"></path>
                    <path d="M12 5l7 7-7 7"></path>
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Questions;
