import React from "react";
import Footer from "../Global/Footer";
import Sidebar from "../Global/Sidebaar";

function OurTeam() {
  return (
    <>
      <Sidebar />
      {/* <TabButton /> */}
      <div className="title-about">
        <h1>About Us</h1>
      </div>

      <div className="aboutUsImage">
        <img src="../Images/gallery/ImageSeven.jpeg" alt="" />
      </div>


      <div className="content">
        <p>
        Shaikh & Associates comes with a team of highly dedicated chartered accountants in Mumbai. We are committed to offering top-notch financial and business solutions. We are a full service accounting firm. Services we offer include, accounting and tax planning, Compliance outsourcing, helping setup a foreign subsidiary company in India, and many more. As one of the well-known CA firms in Mumbai, we aim to allow clients achieve financial success and grow quickly by absorbing all the compliance burden and letting the business owner concentrate on core business operations.

So, if you are looking for stress free accounting, compliances and auditing services in Mumbai, you may reach out to Shaikh & Associates and opt for their services.
We are a firm of chartered accountants based in Mumbai and branches across the country serving to the Canada and in Dubai.
        </p>
      </div>


      
      <Footer />
    </>
  );
}

export default OurTeam;
