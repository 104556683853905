import React from 'react';
import Sidebar from '../Global/Sidebaar';
import Footer from '../Global/Footer';

const AuditingSer = () => {
  return (
    <>
      <Sidebar />
      <div>
        <div className='taxation-main-head'>
            <h2>Auditing</h2>
            <div>
                <p>At Shaikh & Associates, our services extend to comprehensive auditing solutions. Our experienced professionals can conduct thorough financial audits, ensuring accuracy and compliance with accounting standards. Whether for regulatory compliance, financial reporting, or internal control assessment, our auditing services are designed to provide a clear and transparent evaluation of your business's financial health. By leveraging our expertise, you can gain valuable insights into your company's operations, make informed decisions, and enhance overall financial transparency and accountability.</p>
            </div>
        </div>

    </div>
        <Footer />
        </>
  )
}

export default AuditingSer