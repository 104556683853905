import React, { useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const form = useRef();
  const emailInput = useRef();
  const messageInput = useRef();
  const [btnText, setBtnText] = useState('Get in touch')

  const sendEmail = (e) => {
    e.preventDefault();
    setBtnText('Sending...');
    emailjs.sendForm(
      "service_c6dlsfo",
      "template_114vnug",
      form.current,
      "XXv0PWJCeAIhHaCe4"
      )
      .then(
        (result) => {
          toast.success("Your message has been sent successfully!", {
            position: "top-center",
            autoClose: 2000,
            theme: "light",
          });
          form.current.reset();
          setBtnText('Get in touch');
        },
        (error) => {
          alert('Failed to send message');
          setBtnText('Get in touch');
        }
      );
  };
  
  return (
    <>
      <section
        className="min-h-screen bg-cover"
        style={{
          backgroundImage:
            "url(" +
            "https://images.unsplash.com/photo-1563986768609-322da13575f3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" +
            ")",
        }}
      >
        <div className="flex flex-col min-h-screen bg-black/60">
          <div className="container flex flex-col flex-1 px-6 py-12 mx-auto">
            <div className="flex-1 lg:flex lg:items-center lg:-mx-6">
              <div className="text-white bg-blue lg:w-1/2 lg:mx-6 py-8 px-8 rounded-lg">
                <h1 className="text-3xl font-semibold capitalize lg:text-5xl overflow-hidden">
                  Our Clients
                </h1>

                <p className="max-w-xl mt-6">
                  Our client list includes domestic and international entities
                  of variousvsizes from different industries. Our team of
                  experienced professionals provide financial solutions in a
                  manner where client satisfaction is top priority.
                </p>
                <p className="max-w-xl mt-6">
                  We are a Business Taxation Firm India and we ensure
                  confidentiality and professional ethics, names and nature of
                  business of clients are not provided on the website. We have
                  serviced clients from following industries.
                </p>
                <p className="max-w-xl mt-6">
                  We recognize that client satisfaction is the key to our
                  success. Therefore, our service is tailored to ensure that you
                  receive the advice you require to meet your individual needs,
                  regardless of size or complexity.
                </p>
              </div>
              <div className="mt-8 lg:w-1/2 lg:mx-6">
                <div className="w-full px-8 py-10 mx-auto overflow-hidden bg-white shadow-2xl rounded-xl lg:max-w-xl">
                  <h1 className="text-2xl font-medium text-lightBlack capitalize overflow-hidden">
                    Get in touch with us
                  </h1>

                  <p className="mt-4 text-gray-600">
                    Always ready to help you out regarding all your queries.
                  </p>

                  <form className="mt-6" ref={form} onSubmit={sendEmail}>
                    <div className="flex-1">
                      <label className="block mb-2 text-sm text-lightBlack">
                        Full Name
                      </label>
                      <input
                        type="text"
                        name="user_name"
                        placeholder="Name"
                        className="block w-full px-5 py-3 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md focus:border-lightBlack focus:ring-opacity-40 focus:outline-none focus:ring-white"
                      />
                    </div>

                    <div className="flex-1 mt-6">
                      <label className="block mb-2 text-sm text-lightBlack">
                        Email address
                      </label>
                      <input
                        type="email"
                        name="user_email"
                        placeholder="Email"
                        ref={emailInput}
                        required
                        className="block w-full px-5 py-3 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md focus:border-lightBlack focus:ring-opacity-40 focus:outline-none focus:ring-white"
                      />
                    </div>

                    <div className="flex-1 mt-6">
                      <label className="block mb-2 text-sm text-lightBlack">
                        Subject
                      </label>
                      <input
                        type="text"
                        name="user_subject"
                        placeholder="Subject"
                        className="block w-full px-5 py-3 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md focus:border-lightBlack focus:ring-opacity-40 focus:outline-none focus:ring-white"
                      />
                    </div>

                    <div className="w-full mt-6">
                      <label className="block mb-2 text-sm text-lightBlack ">
                        Message
                      </label>
                      <textarea
                        name="message" // add a name attribute to identify the textarea
                        rows={4}
                        ref={messageInput}
                        required
                        className="block w-full h-32 px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md md:h-48 focus:border-lightBlack focus:ring-opacity-40 focus:outline-none focus:ring-white"
                        placeholder="Message"
                      ></textarea>
                    </div>

                    <button className="w-full px-6 py-3 mt-6 text-sm text-white tracking-wide capitalize transition-colors duration-300 transform bg-blue rounded-md focus:outline-none focus:ring focus:ring-blue-400 focus:ring-opacity-50">
                      {btnText}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
};

export default Contact;