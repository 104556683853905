import React from 'react';
import Sidebar from '../Global/Sidebaar';
import Footer from '../Global/Footer';

const Advisory = () => {
    return (
        <div>
            <>
                <Sidebar />
                <div>
                    <div className='taxation-main-head'>
                        <h2>Advisory</h2>
                        <div>
                            <p>With the active support obtained from our competent team of professionals, we have managed to provide quality and effective services to our esteemed clients.</p>
                        </div>
                    </div>

                </div>
                <Footer />
            </>

        </div>
    )
}

export default Advisory