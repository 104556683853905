import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../Images/aboutUs/logo.jpg";

const navbarData = [
  { title: "Home", path: "/" },
  { title: "About Us", path: "/about/our-team" },
  { title: "Services", path: "/services/direct-taxation" },
  { title: "Gallery", path: "/gallery" },
  { title: "Contact Us", path: "/contact" },
]

const Sidebar = () => {
  const selectedRoute = window.location.pathname;
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked((prevClicked) => !prevClicked);
  };

  return (
    <div id="nav">
      <div id="logoo">
        <Link to="/">
          <img className="w-24" src={Logo} alt="logo" />
        </Link>
      </div>



      <div id="nav-div">

        <ul id="navbar" className={clicked ? "active" : ""}>
          {navbarData.map((element, index) => {
            return (
              <li key={index} >
                <Link 
                // className="active"
                 className={element.path === selectedRoute ? "selected" : ""} to={element.path} >
                  {element.title}
                </Link>
              </li>
            )
          }
          )};
        </ul>
      </div>

      <div id="mobile" onClick={handleClick}>
        <i
          id="bar"
          className={
            !clicked ? "fi fi-br-menu-burger" : "fi fi-br-cross"
          }
        ></i>
      </div>
    </div>
  );
};

export default Sidebar;
