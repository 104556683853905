import React from "react";
import Footer from "../Global/Footer";
import Sidebar from "../Global/Sidebaar";
import ServicesRedirectButtons from "../ServicesAllTabs/ServicesRedirectButtons";

const InternalAudit = () => {
  return (
    <>
      <Sidebar />
      <div className="InternalAuditcontainer">
        <div className="ForCenter">
          <ServicesRedirectButtons />

          <div className="internalAuditcontainer-content">
            <div className="internal-audit">
            <h1>AUDITING</h1>
            </div>
            <h2>What’s an Internal Audit</h2>

            <p>
              <strong>Internal Audit</strong> is an independent appraisal
              function established within an organization to examine and
              evaluate its activities as a service to the organization. It is an
              independent, objectives assurance and consulting activity designed
              to add value and improve an organization’s operations. It helps
              organization to accomplish objectives by bringing a systematic,
              disciplined approach to evaluate and improve the effectiveness of
              risk management, control and government process.
            </p>

            <h2>1. Scope and Objectives of Internal Audit</h2>

            <p>
              As per AAS-6, Internal Control System refers to all the policies
              and procedures adopted by the management of the entity to assist
              in achieving management’s objectives ensuring the orderly conduct
              of the business, the accuracy and completeness of accounting
              records, the timely preparation of financial information,
              safeguarding of assets of enterprises and detection of fraud and
              errors in a timely manner. The internal audit function constitutes
              a separate component of internal control with the objective of
              determining whether other internal controls are well designed and
              properly evaluated.
            </p>
            <p>
              The Scope and Objectives of internal audit vary widely and are
              dependent upon the size and structure of the entity and the
              management’s requirements. The internal audit normally operates in
              one or more of the following areas:
            </p>
            <p>
              (a)Review of accounting system and related internal controls:
              Although the establishment of an adequate accounting system and
              related internal controls is the responsibility of the management.
              However it is must that they are reviewed from time to time to
              ensure that they are operating effectively and recommend any
              improvement thereto;
            </p>
            <p>
              (b) Examination of Management of financial and operating
              information : It includes the review of the means used to
              identify, measure, classify and report such information and
              specific inquiry into individual items including tests of
              transaction;
            </p>
            <p>
              (c) Examination of the economy, efficiency and effectiveness of
              operations including the financial controls of an organization:
              This will help the external auditor when it has an important
              bearing on the reliability of the financial records;
            </p>
            <p>
              (d) Physical examination and verification: It includes the
              examination and verification of physical existence and condition
              of the tangible assets of the entity.
            </p>

            <h2>The objects of internal audit can be stated as follows</h2>

            <p>
              (a) Verification of the accuracy and authenticity of the
              financial, accounting and statistical records.
              <br />
              (b) Ascertaining that accepted accounting policies and practices
              have been followed while preparing the financial accounts.
              <br />
              (c) The assets are purchased or disposed under proper
              authorization. Also ensuring that the access to assets is
              restricted to the authorized persons at the authorized times.
              <br />
              (d) Confirming that the liabilities are incurred for the
              legitimate activities of the organization.
              <br />
              (e) The internal checks system operating in the organization is
              sound and economical.
              <br />
              (f) Fraud and errors are prevented and detected.
              <br />
              (g) Reviewing overall operations of internal control system and if
              deviations or weakness are noted, the same are communicated to the
              appropriate authorities on timely basis. This will help in
              instituting corrective actions.
            </p>

            <h2>Internal Audit Framework</h2>

            <p>
              The operation of the internal audit function should comply with
              the following code:
            </p>

            <p>
              <strong>Formal Charter:</strong> The internal audit function
              should have formal charter, including terms of reference, which
              has been approved by board;
              <br />
              <strong>Documentation of Reporting Structure:</strong>
              The reporting structure for internal audit should be clear and
              formally documented. The head of the internal audit function
              should have considerable seniority within the organization and the
              content of all internal audit report should be entirely at his
              discretion. The head of internal audit should report directly to
              the board audit committee and should also have access to the
              chairman of the board and the chairman of the board audit
              committee. Functionally the head of internal audit should report
              within the body to such person as the board decides and to the
              chief executive;
              <br />
              <strong>Detailed Testing:</strong>
              In carrying out its ongoing work, the internal audit function
              should include detailed testing on all specific areas covered by
              the charter in order to ensure that the company is complying fully
              with all requirements and report its finding to the board audit
              committee.
              <br />
              <strong>Competency:</strong>
              The internal audit function should be properly equipped with the
              necessary skills, including the ability to deal with non-financial
              aspects.
              <br />
              <strong>Liasioning with external auditors:</strong>
              The internal audit function should liaise frequently with the
              external auditors so that the potential for co-operation between
              the two is maximized. The work carried out by these two can
              frequently be complementary and effectiveness can be increased
              through regular consultation. (For example, the external auditors
              could offer guidance on particular areas which the internal audit
              function might be reviewing. Conversely the internal audit
              function could provide the external auditor with company specific
              expertise to assist in the evaluation of the system being examined
              as part of the statutory audit)
              <br />
              <strong>Audit committee and external auditors:</strong>
              The Board audit committee should make the external auditor aware
              of corporate governance issues outlined in this documents with
              which the state body is required to comply. The board audit
              committee should periodically consult with the external auditors
              regarding the operation of the internal audit function with
              particular reference to the staffing of the function, the audit
              work programmers being applied and the testing carried out in
              relation to the body’s complainence with the requirement set out
              in this documents.
              <br />
              <strong>Reviewing:</strong>
              The internal audit function body should review compliance with
              procurement and disposal procedures as required by the board audit
              committee from time to time, and report to the board audit
              committee.
            </p>

            <h2>Relationship between Internal and External Audit</h2>

            <p>
              As per AAS-7 on relying upon the work of an internal auditor, the
              external auditor should as a part of his audit evaluate the
              internal audit function to the extent he considers that it will be
              relevant in determining the nature, timing and extent of his audit
              procedures. The scope and objectives of an internal auditor are
              determined by the management while the external auditor has to
              carry out his functions under some statutory requirement
              Nevertheless, some of the means of achieving their respective
              objectives are often similar and thus, much of the work of
              internal auditor may be useful to the external auditor on
              determining the nature, timing and extend on the procedures. The
              external auditor should also evaluate the internal audit function
              to determine the extent of compliance of the substantive and
              compliance procedures. It is to be noted that the degree of
              independence an external auditor has it not with the internal
              auditor and hence, the report of external auditor is his sole
              responsibility and the fact that he has relied on the work of
              internal auditor does not in any way absolve him of his reporting
              responsibility.
            </p>

            <h2>Making Internal Audit Effective:</h2>

            <p>
              Once the external audit has decided to place reliance on the work
              of internal auditor, he should coordinate with him, i.e., he
              should ascertain the internal auditor’s tentative plan for the
              year ,discuss with him the areas of possible reliance, the extent
              of internal audit coverage, test methods, methods of sample
              selection of documentation and review.
            </p>

            <p>
              Further, the external auditor should be granted access to the
              internal audit reports and be kept informed of any significant
              matter which comes to internal auditor’s attention. Similarly, the
              external auditor should ordinarily inform the internal auditor of
              any significant matters which may affect his work.
            </p>
            <p>
              <span>
                The External auditor should take into account the following
                factors in relying on the work of internal auditor:
              </span>
              (a) The adequacy of audit programmes in relation to the scope of
              audit report.
              <br />
              (b) The planning of the work ,supervision and review of the
              documentation of the assistants;
              <br />
              (c) The availability of sufficient appropriate audit evidence.
              <br />
              (d) The appropriateness of conclusion reached and the report
              thereon.
              <br />
              (e) Any expectations or unusual matters disclosed by the internal
              audit.
              <br />
            </p>

            <h2>Evaluating Internal Audit Function</h2>

            <p>
              1. The important aspects that may be considered by the external
              auditor while evaluating the internal audit function are as
              follow:
            </p>

            <p>
              (a) Organization Status: The external auditor should consider any
              constraints or restrictions placed by the management as regards
              the reporting by the internal auditor;
              <br />
              (b) Scope of Function: The nature and depth of audit coverage
              should be ascertained by the external auditor along with whether
              the management acts upon the recommendations of the internal
              auditor;
              <br />
              (c) Technical Competence: Here the professional competence of the
              internal auditor should be evaluated;
              <br />
              (d) Due Professional Experience: The external auditor should
              ascertain whether the internal audit work has been properly
              planned, supervised, review and documented.
              <br />
              (e) Any expectations or unusual matters disclosed by the internal
              audit.
            </p>
            <p>
              2. To facilitate the accumulation of the information necessary for
              the proper review and evaluation of internal controls, the auditor
              can use one of the following to help him to know, assimilate and
              evaluate the same:
            </p>

            <p>
              (a) Narrative Record: This is a complete and exhaustive
              description of the system as found in operation by the auditor.
              Actual testing and observation and necessary before such a record
              can be developed. It may be recommended in cases where no formal
              control system is in operation and would be more suited to small
              business. Further, it allows flexibility and can be adapted to any
              type of enterprise. The narrative record help in the comprehending
              the system operation, identify the weakness of the system and
              incorporating the necessary changes in the system.
            </p>
            <p>
              (b) Check List: This is a series of introduction or question which
              a member of auditing staff must follow to answer. When he
              completes the instruction he must initial the space against each
              instruction or question. For example, the following check list
              pertains to the purchase function of a company:
            </p>

            <p>
              Is purchasing the exclusive responsibilities of purchase
              department?
              <br />
              Is purchase made on the basis of a written order? If yes, whether
              the purchase form standardized?
              <br />
              Are purchases also made on behalf of employees, director etc?
              <br />
              Is special approval necessary where any purchase is not based on
              the lowest quoted price?
              <br />
              Is there any special authorization needed for variation in the
              terms of a purchase order?
              <br />
              Are the copies of purchase order also sent to the accounts
              department and other store department?
            </p>

            <p>
              3. Internal Control Questionnaire: This is a comprehensive series
              of question concerning internal controls. This is the most widely
              used form for collecting information about the existence,
              operational efficiency of internal control in an organization. The
              auditor may prepare a standard questionnaire to be used with
              suitable modification in the case of all audit engagements or he
              may prepare a fresh one for each audit engagement. The question
              should be designed that answer can be provided by a mere ticking
              of the words, “yes” or “no” or “ not applicable” .Questionnaires
              are generally favored in case of audit of large enterprises
              because a considerable amount of time is saved for the auditor by
              eliminating the need for preparing a program for each engagement.
            </p>

            <p>
              4. Flow Chart: It is a graphic presentation of each part of
              company’s system of internal control. A flow chart is considered
              to be the most concise way of recording the auditor’s review of
              the system of the system. It minimizes the time involved in
              getting narrative explanation and gives bird’s eye view of the
              system.
            </p>

            <p>
              It is to be noted all of the above mentioned techniques to
              evaluated internal controls can be effective only if the auditor
              has an understanding of the client’s business.
            </p>

            <h2>Why Choose Our Internal Audit Services?</h2>

            <p>
              (a) Our skilled professional auditors adopt a risk-based approach
              focusing on the objectives and constraints of our clients to
              achieve those objectives.
              <br />
              (b) Our services have been developed for the consistent executive
              ordinances in all internal audit practices throughout the world.
              <br />
              (c) We go further of traditional basics and added tangible value.
              <br />
              (d) We work to build the internal audit throughout a convenient
              and productive tool in today’s Corporate Governance Committee
              environment.
              <br />
              (e) We are providing expertise to implement the audit work in
              specialized areas such as fraud investigations, monitoring of
              projects, implementation of systems, revenue assurance, IT etc.
              <br />
              (f) Our experts team also serve clients in various industries like
              Manufacturing, Financial Services, Telecommunications, Information
              Technology, Healthcare, Media & Entertainment and Consumer
              Business.
              <br />
              (g) Our internal audit services can assist both you and your
              counsel do precisely that with a comprehensive and disciplined
              approach that gives practical solutions, not strong reports.
              <br />
              (h) Our specialists and services are focused on value creation and
              offers you the vision and foresight throughout the enterprise as a
              whole, to address the future head on.
            </p>

            <table class="c">
              <tr className="tablerow">
                <th>New Delhi Office:</th>
                <th>Gurgaon Office:</th>
                <th>Mumbai Office:</th>
              </tr>
              <tr>
                <td>
                  S-13, St. Soldier Tower, G-Block Commercial Centre, Vikas
                  Puri, New Delhi – 110018 (India) +91 11-48560000
                </td>

                <td>
                  1156, Tower B2, 11th Floor, Spaze ITech Park,Sohna Road,
                  Sector 49 Gurgaon, Haryana 122001 (India) + 91 124-4371317 +
                  91 124-4371318
                </td>

                <td>
                  Unit No.3, 1st Floor, New Laxmi Shopping Centre, A-Wing,
                  H.D.Road, Ghatkopar (W), Mumbai – 400086 (India) + 91
                  98202-63544 + 91 022-65957459
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default InternalAudit;
