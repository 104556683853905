import Image from "../Images/gallery/Image.jfif";
import ImageOne from "../Images/gallery/ImageOne.jpeg";
import ImageTwo from "../Images/gallery/ImageTwo.jfif";
import ImageThree from "../Images/gallery/ImageThree.jfif";
import ImageFour from "../Images/gallery/ImageFour.jpeg";
import ImageFive from "../Images/gallery/ImageFive.jfif";
import ImageSix from "../Images/gallery/ImageSix.jpeg";
import ImageSeven from "../Images/gallery/ImageSeven.jpeg";

export const images = [
  {
    id: 1,
    src: Image,
    alt: "travel",
  },
  {
    id: 2,
    src: ImageOne,
    alt: "travel",
  },
  {
    id: 3,
    src: ImageTwo,
    alt: "travel",
  },
  {
    id: 4,
    src: ImageThree,
    alt: "travel",
  },
  {
    id: 5,
    src: ImageFour,
    alt: "travel",
  },
  {
    id: 6,
    src: ImageFive,
    alt: "travel",
  },

  {
    id: 7,
    src: ImageSix,
    alt: "travel",
  },
  {
    id: 8,
    src: ImageSeven,
    alt: "travel",
  },
];
