import React from "react";
import Footer from "../Global/Footer";
import Sidebar from "../Global/Sidebaar";

import ServicesRedirectButtons from "../ServicesAllTabs/ServicesRedirectButtons";

const ServiceTaxAudit = () => {
  return (
    <>
      <Sidebar />
      <div className="service_tax_audit_main_container">
        <div className="service_tax_audit_sub_container">
          <ServicesRedirectButtons />
          <div className="service_tax_audit_sub_container_content">
            <div className="service-tax-audit">
            <h1>SERVICE TAX AUDIT</h1>
            </div>
            <h2>Service Tax Audit in India – An Overview</h2>

            <p>
              Director General of Audit, has published services tax audit
              manual, 2010, 2010. As per the guidance, taxpayers whose annual
              service tax payment (including cash and cenvat) was Rs 30 million
              or more in the preceding financial year may be subjected to
              mandatory audit each year. It is preferable that audit of all such
              units is done by using computer assisted audit program (CAAP)
              techniques. The frequently of audit for other taxpayers would be
              as per following norms:.
            </p>

            <ul>
              <li>
                <p>
                  i. Taxpayers with service tax payment above Rs 30 million
                  (cash+ cenvat) (mandatory units) to be audited every year.
                </p>
              </li>
              <li>
                <p>
                  {" "}
                  ii. Taxpayer with service tax payment between Rs 10 million
                  and Rs 30 million (cash+ cenvat) to be audited once every two
                  years.
                </p>
              </li>
              <li>
                <p>
                  {" "}
                  iii. Taxpayers with service tax payment between 2.5 million
                  and 10 million (cash + cenvat) to be audited once every five
                  year. iv. Taxpayers with service tax payment up to 2.5 million
                  (cash + cenvat) 2% of taxpayers to be audited every year.
                </p>
              </li>
            </ul>

            <p>
              The audit selection guidelines, therefore would apply to the
              non-mandatory taxpayers, forming part of discretionary workload.
              These taxpayers should be selected on the basis of assessment of
              risk potential to the revenue. This process, which is an essential
              feature of audit selection, is known as Risk Assessment. It
              involves the ranking of taxpayers according to quantitative
              indicators of risk known as a “risk parameter”. It is also
              suggested that the taxpayers whose returns were selected for
              detailed scrutiny, the taxpayers who have been selected for audit,
              may not be taken up for detailed scrutiny of their ST-3 returns
              during that year.
            </p>

            <h2>FOCUS OF AUDIT IN SERVICE TAX-BOOKS/RECORDS</h2>
            <p>
              The focus of audit in service tax matters is again to be seen from
              the revenue’s point of view. The objective is to find out whether
              there has been proper and appropriate payment of service tax by an
              assessee.
            </p>

            <h2>BOOKS AND RECORDS FOR SERVICES TAX PURPOSES</h2>
            <p>
              It is an interesting to note that the Finance Act, 1994 or the
              Services Tax Rules, 1994 do not prescribe any set of books or
              records to be maintained by the service tax assessees. The
              assessees are free to maintained their own books/accounts. In fact
              rule 5 of the services tax rules, 1994 provides that the records
              including computerized data as maintained by an assessees in
              accordance with the various laws in force from time to time shall
              be acceptable to the Department. Accordingly, service tax
              assessees who are joint stock companies governed by the provisions
              of Companies Act, 2013 will maintain books/ accounts as prescribed
              under the Companies Act, 2013. Assessees who are registered under
              the Co-operative Societies Act or under the Societies Registration
              Act will maintain books/records under the respective
              rules/regulations framed under the laws governing the assessees.
              Similarly, assesses who are registered as Trust will maintain
              books/accounts as required of them under the law governing the
              trust, if any. Assessees who are receiving foreign contributions
              will maintain books/records specially required of them as
              prescribed under the Foreign Contributions Regulation Act /rules
              framed thereunder. Similarly sole traders and partnership firms
              will maintain accounts/books as required under the income tax
              /sales tax acts including work contracts and also as required
              under the Tax Audit rules under the Income Tax Act 1961 besides
              records under other tax laws and special statutes such as
              Insurance Laws, Banking Laws and Rules and Regulations framed
              thereunder. Normally service tax assessees maintain the following
              records:
            </p>

            <ul>
              <li>
                <p>
                  a) Invoice/bills showing the service charges/job work charges
                </p>
              </li>
              <li>
                <p>b) Bank accounts</p>
              </li>
              <li>
                <p>c) Purchase/Sales accounts</p>
              </li>
              <li>
                <p>d) Petty cash account</p>
              </li>
              <li>
                <p>e) Debit/Credit notes</p>
              </li>
              <li>
                <p>f) Party’s ledger-debtors and creditors</p>
              </li>
              <li>
                <p>g) Commission account</p>
              </li>
              <li>
                <p>
                  h) Accounts based on various expenses under different heads
                </p>
              </li>
              <li>
                <p>i) Tender documents</p>
              </li>
              <li>
                <p>j) Running bills accounts in the case of works contract</p>
              </li>
              <li>
                <p>
                  k) Challans and other documents evidencing transport of goods
                  by land, rail, air and connected documents such as L/R/, R/R,
                  airway bill, bill of entry etc.
                </p>
              </li>
              <li>
                <p>l) Trial Balance</p>
              </li>
              <li>
                <p>m) Balance sheet along with schedules there under</p>
              </li>
              <li>
                <p>n) Bills raised for services rendered</p>
              </li>
              <li>
                <p>
                  o) Contracts/agreements for various purposes such as for civil
                  construction, manpower, supply, technical know-how/transfer,
                  job work non-compete agreements etc.
                </p>
              </li>
              <li>
                <p>p) MOUs</p>
              </li>
              <li>
                <p>q) Purchase orders</p>
              </li>
              <li>
                <p>r) Works orders</p>
              </li>
            </ul>

            <h2>The above are only illustrative and not exhaustive.</h2>
            <p>
              Now that over all services except those in the negative list and
              those services specifically exempted otherwise are brought under
              services tax net the books/records maintained by the service
              provider will vary depending upon the peculiarities of a
              particular service provider. For example, the records maintained
              by an air travel agent will be slightly different from the records
              maintained by consulting engineers or a maintenance/repairs
              services provider or a mandap keeper or a broker. Similarly the
              record maintained by the insurance company providing life/general
              insurance will be different from the records maintained by
              consignment agent of goods. In fact, in the case of such service
              providers such as stock brokers, life insurance companies, general
              insurance companies etc. there are special statutes governing
              their set up and operation and under these statutes they are
              required to maintain certain special type of records.
            </p>

            <p>
              Form the service tax point of view, for the purpose of audit every
              type of records is essential and relevant so as to ascertain
              whether value of taxable service has been correctly arrived at and
              tax has been paid properly in addition to finding out whether any
              taxable services has escaped from the levy. The service tax
              authorities are empowered to call for any books/records or other
              evidence which may be germane to ascertaining the fact whether the
              service tax liability has been properly and appropriately
              discharged or not?
            </p>

            <h2>AUDIT UNDER NEGATIVE LIST BASED TAXATION</h2>
            <p>
              a) Every assessee has to understand that the terms “service” has
              been defined for the first time to mean any activity done for any
              consideration. It will also include service portion in the
              execution of works contacts pertaining to movable or immovable
              goods. It will not include actionable claims or any transaction in
              money. Similarly, service, rendered by a government, services
              rendered by constitutional authority/body, Member of Parliament,
              members of legislative assembly and services provided by an
              employee to the employer pursuant to a contractual employment are
              not within the scope of services. However, where a person works as
              an independent contractor where the relationship is one of
              contract for service, service tax will be attracted eg., a
              manpower supply services provider will be under contract for
              service and not contract of service. Similarly an AMC provider who
              is acting as an independent contractor will be providing service
              under contract for service. These are liable to service tax.
              <br />
              b) All declared services under sections 66E of the finance
              Act,1994 (chapter V) will attract service tax.
              <br />
              d) Where partial exemption from value given in respect of certain
              services vide notification no 26/2012-ST dated 20-6-2012 the
              assesses should avail such partial exemption as per the
              notification including fulfilling of conditions prescribed
              therein.
              <br />
              e) Wherever reverse charge method of taxation has been prescribed
              vide notification no. 30/2012-ST dated 20-6-2012, service
              recipient has also to take care to pay service tax on the portion
              of the service for which he is liable to pay service to the
              government.
              <br />
              f) Service tax is payable on accrual basis with effect from
              1-4-2011. Since there can be difference between the date of
              invoice, date of rendering the service and date of receipt of
              payment for the service. However, w.e.f 1-4-2011 receipt basis of
              taxation has been allowed by way of option to individuals and
              partnership firm if the aggregate value of services provided is Rs
              50 lakhs or less in a financial year. Point of taxation rules has
              been introduced to define at what point of time service is deemed
              to be rendered. Understanding of these rules is very important
              because if there is any delay in payment by not properly
              appreciating these rules, interest will have to be paid.
              <br />
              g) Similarly, place of provision of service rules has been
              notified for the purpose of deciding the place where the service
              is rendered.These rules are relevant for determining the
              following:
              <br />
              i) In which jurisdiction the service provider is required to be
              registered.
              <br />
              ii) Whether the service is rendered outside India or in India and
              if the service is rendered outside the territory of India it is
              not taxable as per rules.
              <br />
              iii) In the case of provision of services to SEZ unit the place of
              provision of service rules will apply and accordingly if the of
              provision of service is in the SEZ unit it will not be taxed.
              <br />
              iv) For determining whether in a given case service is exported or
              not. Therefore a very clear understanding of the Place of
              Provision of Services Rules is necessary.
              <br />
            </p>

            <p>
              h) Every taxable service has to be properly valued according to
              the valuation rules notified for this purpose. Now, there are
              elaborate rules prescribed for valuation
              <br />
              in respect of various types of services.
              <br />
              These rules have to be properly understood and appreciated.
              <br />
              i) Classification/ descriptions of service has to be properly
              understood because:
              <br />
              — It is the description or classification of service which
              determines whether reverse charge method of taxation is applicable
              or not in a given case.
              <br />
              — Whether benefit of any exemption granted can be availed or not
              depends upon on description/classification of service.
              <br />
              — Whether partial exemption from value can be availed or not
              depends upon description/classification of service.
              <br />
              j) Payment of service tax is to be done quarterly in the case of
              individual, sole trading concern and partnership firms.
              <br />
              k) Returns have to be filled on time indicating correct
              particulars therein.
              <br />
              l) Proper records have to be maintained by the assessees so that
              the same can be produced during inspection, audit so as to justify
              the assessees stand.
              <br />
              m) All confirmed payment of service tax has either to be paid or
              shall be agitated in appeal proceedings.
            </p>

            <p>
              If there are any mistakes apparent on record in any order passed
              by any authority the same has to be rectified by making an
              application in that behalf.
            </p>
            <p>
              Thus service tax assesses have to have a good grasp of the
              provision of service tax law contained in the Act/Rules
              notification issued by the Government. Circular issued by the
              Board also help in understanding the department’s view on a
              particular aspect though circular are binding on the departmental
              officers and not binding on the assessees. A fair knowledge of the
              law has laid down in various Tribunal Rules, High Court and
              Supreme Court will also help in proper compliance with the law and
              giving explanation during the audit by the officers attached to
              the department.
            </p>

            <h2>Preparedness of assesses for service tax audit</h2>
            <p>
              All service tax assesses should tend to be well prepared for
              facing the audit by ensuring the following:-
            </p>

            <p>
              a) Keeping their books/records up to date and tidy with supporting
              documents.
              <br />
              b) Ensuring that they are registered for all taxable services
              being provided by them whether in their capacity as service
              provider or in their capacity as service receiver.
              <br />
              c) Where service tax registration has not been obtained for any
              category of service the same should be applied for and the
              existing registrations should be got amended suitably.
              <br />
              d) Whether service provider has ceased to provide any service he
              should have applied for cancelling of service tax registration.
              <br />
              e) Proper records must be maintained for various invoices issued
              for service tax purposes.
              <br />
              f) Generally service tax invoices should be running on serial
              number for the whole year and only one series of invoice should be
              maintained normally.
              <br />
              g) Proof of deposit of service tax in the form of challan
              generated on the computer or deposited in the bank must be kept
              properly on the file. h) All service tax has to be properly
              prepared and filed and kept on record.
              <br />
              i) A review of all transaction other than goods must be undertaken
              to see whether any of those transaction attracts service tax.
              Normally activities on which excise duty or VAT has been paid do
              not attract service tax.
              <br />
              j) All payment made under foreign exchange should be scrutinized
              to see whether any payment related to service received form
              non-resident in which case reverse charge method of taxation will
              be applicable.
              <br />
              k) All receipt of foreign exchange may be scrutinized to see
              whether any receipt is for service rendered to non-resident which
              are normally not taxable being treated as export of service.
              <br />
              l) In the case of works contract proper valuation has to be done
              as per rules.
              <br />
              m) All debit/credit notes issued must be examined to see to
              service tax implication flowing therefore.
              <br />
              n) CENVAT credit records should be kept up to date.
              <br />
              o) CENVAT credit should be taken only on eligible inputs/eligible
              input services and eligible capital goods.
              <br />
              p) Utilization of services tax credit must be in accordance with
              the CENVAT credit rules 2004.
            </p>

            <p>
              Generally well before the commencement of the audit by the
              department a thorough review has to be undertaken by the assesses
              to get well prepared to meet the queries that may be raised by the
              audit team by focusing inter alia on the above aspects. The list
              above is not exhaustive but only illustrative.
            </p>

            <h2>AUDIT OF DECLARED SERVICES</h2>
            <p>
              Declared service is one which is covered by section 66E. The
              object is to remove any confusion or doubts with regards to the
              taxability of these services.
            </p>

            <h2>VALUATION OF BUNDLED SERVICES</h2>
            <p>
              The concept of bundled services has been introduced simultaneously
              with the introduction of Negative list based taxation with effect
              from 1-7-2012. Bundled service has been defined in the Explanation
              to Section 66F of the Finance Act, 1994 (chapter V) to mean the
              bundle of provision of various services wherein an element of one
              service is combined with the element or elements of provision of
              any other service or services.
            </p>

            <h2>AUDIT OF MEGA EXEMPTIONS FROM SERVICE TAX</h2>
            <p>
              Mega Exemption form tax has been granted under Notification
              No.25/2012-ST,dated 20-6-2012,under the Negative List based
              service taxation scheme. Various tax exemptions have been
              compendiously collated under one Notification No. 25/2012 ST,dated
              20-6-2012 which is popularly known as Mega Exemption in view of
              the size of the notification being very big and large. The Mega
              Exemption notification covers a variety of services. Some of these
              services are also subject to certain conditions.
            </p>

            <h2>Audit of Small Scale Exemption facility</h2>
            <p>
              Availing of Small Scale Exemption up to Rs. 1 million under ,dated
              20-6-2012
            </p>
            <p>
              Audit will look into the following aspects while examining the
              records of assesses claiming Small Scale Exemption:-
            </p>

            <p>
              a) Whether aggregate value of services have not exceeded Rs. 1
              million in the financial year preceding the financial year?
              <br />
              b) Whether the service provider is/is not providing the service
              under brand name or trade name of another person?
              <br />
              c) Whether the person liable to pay service tax under reverse
              charge method is not availing the benefit of exemption?
              <br />
              d) Whether the service provider has opted not to avail exemption,
              and if so, whether he has refrained from withdrawing from that
              option during the financial year?
              <br />
              e) Whether during the period of availing exemption the service
              provider has not availed CENVAT credit of the service tax paid on
              any input services?
              <br />
              f) Whether service provider has not availed credit on capital
              goods received in the premises during the period when he was
              availing SSI exemption?
              <br />
              g) Whether the CENVAT credit availed by the service provider was
              only in respect the period after crossing the SSI exemption limit
              or not? h) In case the service providers opts to avail exemption
              in succeeding financial year (having chosen to pay service tax in
              the preceding financial year so):-
              <br />
              i. Whether while exercising such option to avail exemption he has
              paid an amount equal to CENVAT credit taken by him in respect of
              any inputs lying in stock or process on the date on which he opts
              to availing such exemption?
              <br />
              ii. Whether he reversed the balance CENVAT credit if any in his
              books. Whether the exemption which has been availed by the service
              provider covers the taxable services provided from more than one
              premises from where the service are provided and the values have
              been aggregated and such aggregate value does not exceed Rs. 1
              million in the preceding financial year? Aggregate value for this
              purpose refers to total value of services charged in the first
              consecutive invoices issued during the financial year but does not
              include value charged towards wholly exempted service.
            </p>

            <p>
              In many cases, it is found that small scale service providers are
              not aware of the scope of the small scale exemption under
              Notification No. 33/2012-ST, dated 20-6-2012 and they commit
              mistakes/error out of ignorance or deliberately by-
            </p>

            <p>
              a) Not properly disclosing the value of the taxable services: b)
              By not aggregating the value of taxable services provided from
              different premises:
              <br />
              c) By availing exemption separately or each category of service
              provided by them;
              <br />
              d) By suppressing the facts of providing service under other brand
              name;
              <br />
              e) By availing credit in the succeeding financial year even where
              the value of taxable services provided in the preceding financial
              year exceeds Rs. 1 million ;
              <br />
              f) Sometimes, the person claiming the service under reverse charge
              method also avails wrongly small scale exemption which is not
              permissible.
            </p>

            <p>Audit will look into all the above aspects.</p>

            <h2>AUDIT OF SERVICES UNDER REVERSE CHARGE METHOD</h2>
            <p>
              Reverse charge method of taxation refers to a situation where the
              service recipient has been made liable to pay service tax to the
              government in respect of services received by him. At present in
              terms of Notification No. 30/2012ST, dated 20-6-2012, w.e.f.
              1-7-2012, 11 categories of services has been notified for the
              purpose of reverse charge method of taxation.
            </p>

            <p>Accordingly the taxable services:-</p>
            <p>
              i. Provided or agreed to be provided by an insurance agent to any
              person carrying on the insurance business;
              <br />
              ii. Provided or agreed to be provided by a goods transport agency
              in respect of transportation of goods by road, where the person
              liable to pay freight is,-
              <br />
              (a) any factory registered under or governed by the Factories Act,
              1948;
              <br />
              (b) any society registered under the Societies Registration
              Act,1860 (21 of 1860) or under any other law for the time being in
              force in any part of India;
              <br />
              (c) any co-operative society established by or under any law;
              <br />
              (d) any dealer of excisable goods, who is registered under the
              Central Excise Act, 1944 (1 of 1944) or the rules made there
              under;
              <br />
              (e) any body corporate established, by or under any law; or
              <br />
              (f) any partnership firm whether registered or not under any law
              including association of persons;
              <br />
              iii. Provided or agreed to be provided by way of sponsorship to
              any body corporate or partnership firm located in the taxable
              territory;
              <br />
              (iv) Provided or agreed to be provided by,-
              <br />
              A. An arbitral tribunal, or
              <br />
              B. An individual advocate or a firm of advocates by way of support
              services, or
              <br />
              C. Government or local authority by way of support servicers
              excluding,-
              <br />
              (1) renting or immovable property, and
              <br />
              (2) services specified in sub-clauses (i),(ii) and (iii) of clause
              (a) of section 66D of the Finance Act, 1994,
              <br />
              To any business entity located in the taxable territory;
              <br />
              (v) provided or agreed to be provided by way or renting of a motor
              vehicle designed to carry passengers to any person who is not in
              the similar line of business or supply of manpower for any purpose
              or service portion in execution of works contract by any
              individual, Hindu Undivided Family or partnership firm, whether
              registered or not, including association of persons, located in
              the taxable territory to a business entity registered as body
              corporate, located in the taxable territory;
              <br />
              (B) provided or agreed to be provided by any person which is
              located in a non-taxable territory and received by any person
              located in the taxable territory.
            </p>
            <p>
              (II)The extend of service tax payable thereon by the person who
              provides the service and the person who receives the service for
              the taxable services specified (I) above shall be specified in the
              following table, namely:-
            </p>

            <table
              style={{
                borderCollapse: "collapse",
                width: "100%",
                marginTop: "50px",
                marginBottom: "50px",
                tableLayout: "auto",
              }}
              className="d"
            >
              <tr className="tablerows">
                <th
                  style={{
                    textAlign: "center",
                    padding: "8px",
                    backgroundColor: "#a6cbfa",
                  }}
                >
                  S. No.
                </th>

                <th
                  style={{
                    textAlign: "start",
                    padding: "8px",
                    backgroundColor: "#a6cbfa",
                  }}
                >
                  Description of a service
                </th>
                <th
                  style={{
                    textAlign: "start",
                    padding: "8px",
                    backgroundColor: "#a6cbfa",
                  }}
                >
                  {" "}
                  Percentage of service tax payable by the person providing
                  service
                </th>

                <th
                  style={{
                    textAlign: "start",
                    padding: "8px",
                    backgroundColor: "#a6cbfa",
                  }}
                >
                  Percentage of service tax payable by the person receiving the
                  service
                </th>
              </tr>

              {/* ------------------------------ */}

              <tr>
                <td>1.</td>

                <td>
                  In respect of services provided or agreed to be provided by an
                  insurance agent to any person carrying on insurance business
                </td>

                <td>Nil</td>

                <td>100%</td>
              </tr>

              <tr>
                <td>2.</td>

                <td>
                  In respect of services provided or agreed to be provided by a
                  goods transport agency in respect of transportation of goods
                  by road
                </td>

                <td>Nil</td>

                <td>100%</td>
              </tr>

              <tr>
                <td>3.</td>

                <td>
                  In respect of services provided or agreed to be provided by
                  way or sponsorship
                </td>

                <td>Nil</td>

                <td>100%</td>
              </tr>

              <tr>
                <td>4.</td>

                <td>
                  In respect of services provided or agreed to be provided by an
                  arbitral tribunal
                </td>

                <td>Nil</td>

                <td>100%</td>
              </tr>

              <tr>
                <td>5.</td>

                <td>
                  In respect of services provided or a agreed to be provided by
                  individual advocate or a firm of advocates by way of legal
                  services
                </td>

                <td>Nil</td>

                <td>100%</td>
              </tr>

              <tr>
                <td>6.</td>

                <td>
                  In respect of services provided or agreed to be provided by
                  Government or local authority by way of support services
                  excluding,-(1)renting of immovable property, and (2) services
                  specified in sub-clauses (i), (ii) and (iii) of clause (a) of
                  section 66D of the Finance Act,1994
                </td>

                <td>Nil</td>

                <td>100%</td>
              </tr>

              <tr>
                <td>7.</td>

                <td>
                  (a)In respect of services provided or agreed to be provided by
                  way of renting of a motor vehicle designed to carry passengers
                  on abated value to any person who is not engaged in the
                  similar line of business (b)In respect of services provided or
                  agreed to be provided by way of renting of a motor vehicle
                  designed to carry passengers on non-abated value to any person
                  who is not engaged in the similar line of business
                </td>

                <td>Nil50%</td>

                <td>100%50%</td>
              </tr>

              <tr>
                <td>8.</td>

                <td>
                  In respect of services provided or agreed to by provided by
                  way of supply of manpower for any purpose
                </td>

                <td>25%</td>

                <td>75%</td>
              </tr>

              <tr>
                <td>9.</td>

                <td>
                  In respect of services provided or agreed to be provided in
                  service portion in execution of works contract
                </td>

                <td>50%</td>

                <td>50%</td>
              </tr>

              <tr>
                <td>10.</td>

                <td>
                  In respect of services provided or agreed to be provided in
                  service portion in execution of works contract
                </td>

                <td>Nil</td>

                <td>100%</td>
              </tr>
            </table>

            <p>
              <strong>Explanation I</strong> : The person who pays or is liable
              to pay freight for the transportation of goods by road in goods
              carriage, located in the taxable territory shall be treated as the
              person who receives the service for the purpose of this
              notification.
            </p>

            <p>
              <strong>Explanation II</strong> : In works contract services,
              where both service provider and service recipient is the persons
              liable to pay tax, the service recipient has the option of
              choosing the valuation method as per choice, independent of
              valuation method adopted by the provider of service .
            </p>

            <h2>
              Focus of Audit in respect of reverse charge method prescribed for
              certain services
            </h2>
            <p>
              While undertaking the audit, the audit teams will see/examine-
            </p>

            <p>
              a) Whether the recipient of service has registered for the service
              in respect of which he is liable under reverse charge method will
              also be looked into ?
              <br />
              b) List of services which are covered by reverse charge method
              from the records of the assessee.
              <br />
              c) Look into the documents pertaining to those services such as
              invoices raised by the service provider, any tender, agreement or
              work order in the case on any contract pertaining to construction
              work or AMC services,
              <br />
              d) Study whether the assessee has correctly discharged the service
              tax under reverse charge method to the extent indicated in the
              notification.
              <br />
              e) Examine whether certain services that otherwise attract reverse
              charge method have escaped tax under reverse charge method.
              <br />
              f) Whether the correct percentage of tax to be paid by service
              receiver has been discharged by the assessee or not?
              <br />
              g) In the case of renting of motor vehicles different percentages
              have been prescribed for discharge of service tax under reverse
              charge method and whether the correct percentage of service tax
              has been paid or not.
              <br />
              h) Proof of payment of service tax under reverse charge method
              with reference to challan, particulars given in the return etc.
              may be verified.
              <br />
              i) In the case of works contract, it will be examined as to
              whether the contract pertains to original work or it pertains to
              movable or immovable property or any other service other than the
              above because service tax to be paid is on different percentages
              of value namely, 40%, 60% and 70% under the Service Tax Valuation
              Rules.
              <br />
              j) In the case of services provided by Non-resident , the detailed
              documentary evidence will be called for – such as Contracts,
              E-Mails, Bank advice of remittance of foreign exchange, entries in
              the books of account etc.
            </p>

            <h2>AUDIT OF PLACE OF PROVISION OF SERVICE</h2>
            <p>
              For the first time, coinciding with the introduction of Negative
              List based scheme of taxation, Government has notified Place of
              Provision of Service Rules vide. Notification No.28/2012-ST,dated
              20-6-2012. This consists of 14 Rules in all.
            </p>

            <p>
              <strong>Thus the Audit team will closely examine—</strong>
            </p>
            <p>
              Firstly, as to whether the services have been provided in India
              and If used whether tax has been paid or not?
              <br />
              Secondly, in respect of certain services for which payment has
              been received in convertible foreign exchange, the audit team will
              examine whether the service in the instant case has been provided
              outside India and constitute export?
              <br />
              Thirdly, whether the service provider has claimed any exemption in
              respect of services provided in SEZ Zone or to a SEZ Unit in its
              location, whether the service has been so actually provided there
              at the SEZ/SEZ unit?
              <br />
              Fourthly, whether in respect of certain services provided in the
              state of Jammu & Kashmir, the Rule prescribed have been applied
              correctly or not by the assessee so as to justify the tax not
              having been paid thereon? Fifthly, whether in respect of import of
              service, tax has been paid in India under the reverse charge
              method because in the case of import, place of provision of
              Service is India.
              <br />
              Every assessee, therefore needs to be well prepared to answer the
              Audit queries pertaining to the place of provision of services.
            </p>

            <h2>Service Tax Auditors in Delhi, Mumbai and Gurgaon</h2>
            <p>
              We provide our esteemed services for Service Tax Audit in India by
              fulfilling the demands of different sectors and we are capable of
              undertake our clients with{" "}
              <strong>Service Tax Audit under section 44AB</strong>. Such audits
              assure that the evaluated Tax Service according to the law
              complies with the provisions of the Finance Act, 1994 Chapter ‘V’
              and the rules made thereunder with. It is an indirect tax run by
              the Central Excise Department.
            </p>

            <p>
              <strong>
                Neeraj Bhagat & Company have been providing Tax Advisor
                Services, Service Tax Auditing, So you can speak and contact to
                NBC at Delhi, Mumbai and Gurgaon locations by visiting Google
                mapping also:
              </strong>
            </p>

            <table style={{ marginTop: "50px" }} class="c">
              <tr className="tablerow">
                <th>New Delhi Office:</th>
                <th>Gurgaon Office:</th>
                <th>Mumbai Office:</th>
              </tr>
              <tr>
                <td>
                  S-13, St. Soldier Tower, G-Block Commercial Centre, Vikas
                  Puri, New Delhi – 110018 (India) +91 11-48560000
                </td>

                <td>
                  1156, Tower B2, 11th Floor, Spaze ITech Park,Sohna Road,
                  Sector 49 Gurgaon, Haryana 122001 (India) + 91 124-4371317 +
                  91 124-4371318
                </td>

                <td>
                  Unit No.3, 1st Floor, New Laxmi Shopping Centre, A-Wing,
                  H.D.Road, Ghatkopar (W), Mumbai – 400086 (India) + 91
                  98202-63544 + 91 022-65957459
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ServiceTaxAudit;
