import "tw-elements";
import React from "react";
import DirectTax from "../components/AllServices/DirectTax";

const Services = () => {
  return (
    <>
      <div>
        {/* <ServicesRedirectButtons /> */}
        <DirectTax />
      </div>
    </>
  );
};

export default Services;
