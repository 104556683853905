import React from "react";
import Footer from "../Global/Footer";
import Sidebar from "../Global/Sidebaar";

import ServicesRedirectButtons from "../ServicesAllTabs/ServicesRedirectButtons";

const TaxDueDiligence = () => {
  return (
    <>
      <Sidebar />
      <div className="taxduediligence-main-container">
        <div className="taxduediligence-sub-container">
          <ServicesRedirectButtons />
          <div className="taxduediligence-sub-container-content">
            <div className="tax-due-diligence">
            <h1>TAX DUE DILIGENCE</h1>
            </div>
            <h2>
              DUE DILIGENCE TAX PERSPECTIVE
              <br /> Due Diligence- An Introduction
            </h2>

            <p>
              Any merger and acquisition transaction has to be carefully planned
              and executed ; therefore, before closing a deal and to make more
              informed decisions, the buyer normally carries out certain agreed
              upon procedures to assess the deal from commercial, financial, tax
              and legal standpoints. Beside important issues, this includes a
              spectrum of tax and regulatory issues such us exchange control,
              income taxes, indirect taxes and capital market regulations.
            </p>

            <p>
              The agreed upon procedures are normally described as a ‘due
              diligence exercise’. The expiration ‘due diligence’ is not define
              by any statute, nor is there any legal binding to carry out the
              same; on the contrary, it is a creation of conventional practices.
            </p>

            <p>
              The need for a due diligence exercise can perhaps be linked to the
              phrase forewarned is forearmed’. Although due diligence is not a
              panacea against investment failures, it provides the potential
              buyer with relevant information and business/targets proposed to
              be acquired and helps manage associated risks.
            </p>

            <h2>Due Diligence versus Statutory Audit/Internal Audit</h2>
            <p>
              In India, companies are statutory required to get their accounts
              audited by an independent Chartered Accountant (known as statutory
              audit). In certain cases, companies are even required to carry out
              an internal audit relating to their processes.
            </p>

            <p>
              Due diligence is quite distinct from statutory and internal
              audits.
            </p>
            <p>
              The keys differences between due diligence and statutory/internal
              audit are tabulated below.
            </p>

            <h2>Types of due diligence</h2>
            <p>
              Due diligence can be typically categorized into various types from
              two Perspectives. Firstly, from the perspective of who actually
              carries out the due diligence, secondly, from the perspective of
              ‘what’ is being carried out as part of the due diligence.
            </p>

            <h2>Primary interest perspective</h2>
            <p>
              Based on the first perspective (i.e., ‘who’ actually carries out
              the due diligence or the ‘primary interest’ perspective), there
              are two types of due diligence.
            </p>

            <p>
              {"\u2022"} Buyer due diligence
              <br />
              {"\u2022"} Vendor due diligence
            </p>

            <h2>Buyer due diligence</h2>
            <p>
              When one refers to ‘due diligence’, it normally means a buyer due
              diligence. It is the acquirer or the buyer who is interested in
              getting a better insight into the exposures or upsides of the
              target. Hence it is in the acquirer’s/ buyer’s interest that he
              carries out the due diligence before closing the deals. Normally
              all buyers carry out a due diligence before making their
              acquisition. The buyer generally appoints consultants to carry out
              the due diligence and provide expert advice on the implication of
              the findings of the due diligence.
            </p>

            <h2>Vendor due diligence</h2>
            <p>
              In recent years, vendor due diligence is gaining popularity. This
              is when the target’s management carries out a due diligence on the
              target. The target’s management, on its own accord, appoints
              consultants to carry out the due diligence on the target. The
              consultant would provide management with its vendor due diligence
              report highlighting the exposure or upside in the targets. Vendor
              due diligence is useful in cases where the target’s management
              proposes to invite more than one investor /acquirer. The target’s
              management would provide this vendor due diligence report to
              prospective investors/acquirer.
              <br />
              This prospective investors/acquirer place reliance on the vendor
              due diligence report and make their investment acquisition
              decisions. In certain cases, the investors/acquirer may want to
              investigate into a particular area that may be discussed in the
              vendor due diligence report to gain a better insight into the
              area.
            </p>

            <h2>Functional Perspective</h2>
            <p>
              Based on the second perspective (i.e., ‘what’ is being carried out
              as part of the due diligence or the ‘functional’ perspective),
              there can be various types of due diligence. Different situations
              may call for varying types of due diligence. The most commonly
              carried out due diligence in India are as follows:
            </p>

            <p>
              {"\u2022"} Commercial
              <br />
              {"\u2022"} Technical
              <br />
              {"\u2022"} Environmental
              <br />
              {"\u2022"} Human resource
              <br />
              {"\u2022"} Legal
              <br />
              {"\u2022"} Financial tax (direct and indirect tax)
            </p>

            <p>
              The ensuring paragraphs discuss the various aspects relating to
              the last item of the above list, viz, tax diligence.
            </p>

            <h2>Tax due diligence – significance</h2>
            <p>
              Tax is one of the material and unavoidable costs. Hence, tax due
              diligence plays a significant role in M&A decision making, though
              tax is normally not the primary consideration in the context of M
              & A transaction.
            </p>

            <p>
              Conventionally, tax due diligence is carried out to understand the
              tax profile of the target and to uncover and quantify tax
              exposures. However, tax due diligence also encompasses identifying
              any tax upside (potential tax benefits that are not being
              claimed/envisaged by the target) which may be available with the
              target. It also assists in identifying and developing a suitable
              acquisition structure for the deal in question.
            </p>

            <p>
              In practice, the most common form for tax risk mitigation is
              through tax warranties and indemnities in the agreement. The buyer
              needs to be balanced while negotiating for this tax protection to
              ensure that it does not impact the commerciality of the
              transaction for the seller.
            </p>

            <p>To summaries, a tax due diligence is normally carried out to:</p>

            <p>
              {"\u2022"} Validate the representation made by the seller at the
              time of pre-deal negotiations.
              <br />
              {"\u2022"} Validate the assumptions made by the buyer in valuing
              the target.
              <br />
              {"\u2022"} Identify any material tax exposures that may be
              residing with the target.
              <br />
              {"\u2022"} Identify any material upsides (potential tax benefits
              that are not being claimed/envisaged by the targets)
              <br />
              {"\u2022"} Structure the deal in a tax-efficient manner.
            </p>

            <h2>Full Scope Tax due Diligence Approach- Income tax</h2>
            <p>
              A typically tax due diligence exercise (in the context of a full
              scope due diligence) entail a review of relevant documents
              wherefrom the tax information can be sourced. The typically
              sources of obtaining the relevant tax information are as follows:
            </p>

            <p>
              i) The financial statements of the targets
              <br />
              ii) Its related tax records
            </p>

            <h2>i. Review of financial statements</h2>
            <p>
              Relevant tax information is normally available in the balance
              sheet and Related schedules, which disclose the aggregate tax
              provision and aggregate taxes paid by a company. It is also
              accessible from the profit and loss account, which discloses the
              current tax charge and deferred tax charge for the relevant year.
              The notes to the account reveal information regarding accounting
              policy relating to income tax and deferred taxes, and contingent
              liability with regards to tax disputes demand against the company.
              The auditor’s report discloses details of outstanding tax dues. In
              certain cases, the director’s report discusses the status of
              ongoing tax litigations/assessments.
            </p>

            <p>
              A review and analysis of each of the items of the tax
              informationdisclosed by the financial statements provides the
              reviewer a fair insight of the tax position of the target
              concerned. Needless to state, the review and analysis, coupled
              with intelligent discussion with the management of the targets,
              would go a long way in obtaining a better perspective in this
              regard.
            </p>

            <p>
              The items of tax information available in the financial statements
              are as follows:
            </p>

            <p>
              a) Review of contingent liabilities
              <br />
              b) Review of tax provision and tax paid in the balance sheet
              <br />
              c) Analysis of deferred tax
              <br />
              d) Analysis of effective tax rate
              <br />
              e) Review of MAT paid and MAT credit
            </p>

            <p>
              The ensuing paragraphs discuss how the review and analysis of each
              of the Items of tax information available in the financial
              statements prove to be of relevance and significance.
            </p>

            <h2>a) Review of Contingent liabilities</h2>
            <p>
              The disclosure about contingent liabilities in the notes to the
              accounts of Financial statements reveal significant tax
              information about the target. The reviewer gets a fair sense of
              pending tax disputes/demands by reviewing the contingent liability
              schedule. As a next step a reviewer may want to analyze the
              related tax assessment/appellate records to better understand the
              disclosure made in the contingent liability schedule.
            </p>

            <h2>
              b) Review of the tax provision and taxes paid in the balance sheet
            </h2>
            <p>
              Normally the balance sheets of targets disclose the tax provision
              and taxes paid as of the balance sheet date in two possible
              formats, as given below
            </p>

            <p>
              <strong>Format 1</strong>- The tax provision is disclosed
              separately under the current liability section of the balance
              sheet, and the taxes paid are disclosed separately under the loans
              and advance’ section of the balance sheet.
            </p>

            <p>
              {" "}
              <strong>Format 2</strong>- The tax provision and taxes paid are
              netted off, and the net figure is shown in the balance sheet.
              Where the net figure represents the excess of taxes paid over tax
              provision, it is disclosed under the loans and advances section of
              the balance sheet. Where the net figure represents the excess of
              tax provisions over taxes paid, it is disclosed under the current
              liability section of the balance sheet.
            </p>

            <p>
              Normally, analyzing the tax provision and taxes paid as appearing
              in the balance sheet is not of much assistance to the reviewer.
              However, where a year-wise breakdown of these numbers is
              available, it provides a better insight into the tax position of
              the targets.
            </p>

            <p>
              Once the year-wise details are received, the first flush
              impression that one gets about the targets tax position is
              regarding the longevity of the tax assessments/disputes of the
              targets. For instance in a case where the year-wise breakdown
              provides details of the last 15 year as against a case where the
              details are from the last 4 years, normally the tax position of
              the target with a 15 year tax assessment/dispute history are found
              to be relatively more complex than those of the target with a
              4-year tax assessment/dispute history.
            </p>

            <p>
              A detailed review of the tax provision and taxes paid for each of
              the year would be either confirm or dismiss this prima facie
              impression of the tax position.
            </p>

            <p>
              Without discussing technical details about the Indian tax laws, it
              would be relevant to state a specific provision of the Indian tax
              laws. A tax payer is required to pay income tax on a progressive
              basis during the year in question, known as a advance tax
              mechanism. In view of the above, in an idealistic scenario, all of
              the taxes provided by a company should be paid within the year in
              year in question. However, in practice, such an ideal situation is
              rarely achieved.
            </p>

            <p>
              It is possible that in a particular year, the tax provision is
              more than tax paid and simultaneously in another year taxes paid
              are more than the tax provision. On a detailed analysis of each of
              these situations, a reasonable perspective of the tax position of
              the target can be understood.
            </p>

            <p>
              In practice there could possibly be three situations that could
              arise in this regard:
            </p>

            <p>
              1. Tax provided is more than taxes paid
              <br />
              2. Tax provide is less than taxes paid
              <br />
              3. Tax provided is equal to taxes paid
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TaxDueDiligence;
