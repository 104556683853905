import React from "react";
import Logo from "../../Images/aboutUs/logo.jpg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-blue text-white body-font">
      <div className="container px-2 lg:px-20 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
        <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
          <Link
            to="/" onClick={()=> {window.scrollTo(0,0)}}
            className="flex title-font font-medium items-center md:justify-start justify-center text-white"
          >
            <img src={Logo} alt="logo" />
          </Link>
          <p className="mt-2 text-sm text-white overflow-hidden">
            We make your Accounting and Investment easier
          </p>
        </div>
        <div className="flex-grow flex flex-wrap md:pl-10 -mb-10 md:mt-0 mt-10 md:text-left text-center">
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-white tracking-widest text-sm mb-3 overflow-hidden">
              OUR SERVICES
            </h2>
            <nav className="list-none mb-10 ">
              <li className="mb-1 overflow-hidden">
                <Link className="hover:text-[#ffe6e6] cursor-pointer" to="/services/direct-taxation" onClick={()=> {window.scrollTo(0,0)}}>
                  Direct Taxation
                </Link>
              </li>
              <li className="mb-1 overflow-hidden">
                <Link to="/services/indirect-taxation" className="hover:text-[#ffe6e6] cursor-pointer" onClick={()=> {window.scrollTo(0,0)}}>
                  Indirect Taxation
                </Link>
              </li>
              <li className="mb-1 overflow-hidden">
                <Link to="/services/taxable-income-of-expatriates" className="hover:text-[#ffe6e6] cursor-pointer" onClick={()=> {window.scrollTo(0,0)}}>
                  Taxable Income
                </Link>
              </li>
              <li className="mb-1 overflow-hidden">
                <Link to="/services/internal-audit" className="hover:text-[#ffe6e6] cursor-pointer" onClick={()=> {window.scrollTo(0,0)}}>
                  Internal Audit
                </Link>
              </li>
              <li className="mb-1 overflow-hidden">
                <Link to="/services/service-tax-audit" className="hover:text-[#ffe6e6] cursor-pointer" onClick={()=> {window.scrollTo(0,0)}}>
                  Service Tax Audit
                </Link>
              </li>
              <li className="mb-1 overflow-hidden">
                <Link to="/services/tax-due-diligence" className="hover:text-[#ffe6e6] cursor-pointer" onClick={()=> {window.scrollTo(0,0)}}>
                  Tax Due Diligence
                </Link>
              </li>
              <li className="mb-1 overflow-hidden">
                <Link to="/services/registration-of-foreign-companies" className="hover:text-[#ffe6e6] cursor-pointer" onClick={()=> {window.scrollTo(0,0)}}>
                  Registration of Foreign Companies
                </Link>
              </li>
            </nav>
          </div>
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-white tracking-widest text-sm mb-3 overflow-hidden">
              LINKS
            </h2>
            <nav className="list-none mb-10">
              <li className="mb-1 overflow-hidden">
                <Link to="/" onClick={()=> {window.scrollTo(0,0)}} className="hover:text-[#ffe6e6] cursor-pointer">
                  Home
                </Link>
              </li>
              <li className="mb-1 overflow-hidden">
                <Link
                  to="/about/our-team" onClick={()=> {window.scrollTo(0,0)}}
                  className="hover:text-[#ffe6e6] cursor-pointer"
                >
                  About us
                </Link>
              </li>
              <li className="mb-1 overflow-hidden">
                <Link
                  to="/services" onClick={()=> {window.scrollTo(0,0)}}
                  className="hover:text-[#ffe6e6] cursor-pointer"
                >
                  Services
                </Link>
              </li>
              <li className="mb-1 overflow-hidden">
                <Link
                  to="/gallery" onClick={()=> {window.scrollTo(0,0)}}
                  className="hover:text-[#ffe6e6] cursor-pointer"
                >
                Gallery
                </Link>
              </li>
              <li className="mb-1 overflow-hidden">
                <Link
                  to="/contact" onClick={()=> {window.scrollTo(0,0)}}
                  className="hover:text-[#ffe6e6] cursor-pointer"
                >
                  Contact us
                </Link>
              </li>
            </nav>
          </div>
          <div className="lg:w-2/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-white tracking-widest text-sm mb-3 overflow-hidden">
              CONTACT INFORMATION
            </h2>
            <nav className="list-none mb-10">
              <li className="mb-1 overflow-hidden">
                <p className="hover:text-[#ffe6e6]">296/2353 Motilal Nagar 2,</p>
                <p>Mahatma Gandhi road, Near Manthan Hotel,</p>
                <p>Goregaon west, Mumbai - 400104</p>
              </li>
              <br />
              <li className="mb-1 overflow-hidden">
                <p className="hover:text-[#ffe6e6]">
                  {" "}
                  <span>India </span> +91-9821518350 / +91-9821054140
                </p>
                <p className="hover:text-[#ffe6e6]">
                  {" "}
                  <span>Canada : </span> +1 6478187955
                </p>
                <p className="hover:text-[#ffe6e6]">
                  {" "}
                  <span>Dubai : </span> +971 507837141
                </p>
              </li>
              <li className="mb-1 flex justify-center md:justify-start">
                {/* <div className="hover:text-[#ffe6e6]">
                  {" "}
                  <span className="text-bold">Email : </span>{" "}
                  <div>
                    <p>

                  Shaikh.associates1978@gmail.com 
                    </p>
                  Shaikhliyakat0311@gmail.com
                  </div>
                </div> */}
                <div className="w-14">Email : </div>
                <div className="ml-1">
                  <p>Shaikh.associates1978@gmail.com</p>
                  <p>Shaikhliyakat0311@gmail.com</p>
                </div>
              </li>
            </nav>
          </div>
        </div>
      </div>
      <div className="bg-gray-100">
        <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
          <p className="text-gray-500 text-sm text-center sm:text-left mb-1 overflow-hidden">
            All rights Reserved © {new Date().getFullYear()}
          </p>
          <span className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
            <Link className="text-gray-500 cursor-pointer mb-1 overflow-hidden">
              {/* <svg
                fill="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
              </svg> */}
            </Link>
            <Link className="ml-3 text-gray-500 cursor-pointer mb-1 overflow-hidden">
              {/* <svg
                fill="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
              </svg> */}
            </Link>
            <Link className="ml-3 text-gray-500 cursor-pointer mb-1 overflow-hidden">
              {/* <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
              </svg> */}
            </Link>
            <Link className="ml-3 text-gray-500 cursor-pointer mb-1 overflow-hidden">
              {/* <svg
                fill="currentColor"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="none"
                  d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
                ></path>
                <circle cx="4" cy="4" r="2" stroke="none"></circle>
              </svg> */}
            </Link>
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
