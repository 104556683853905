import React from 'react';
import Sidebar from '../Global/Sidebaar';
import Footer from '../Global/Footer';

const Outsourcing = () => {
    return (
        <div>
            <>
                <Sidebar />
                <div>
                    <div className='taxation-main-head'>
                        <h2>Outsourcing</h2>
                        <div>
                            <p>Failing to keep up with changing rules is too great a risk to undertake? We help you minimize risks associated and professionally meet with all your management needs.</p>
                        </div>
                    </div>

                </div>
                <Footer />
            </>
        </div>
    )
}

export default Outsourcing