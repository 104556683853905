import { createBrowserRouter } from "react-router-dom";

import App from "../App";
import ContactUs from "../Pages/ContactUs";
import Gallery from "../Pages/Gallery";
import Services from "../Pages/Services";
import DirectTax from "../components/AllServices/DirectTax";
import IndirectTaxation from "../components/AllServices/IndirectTaxation";
import Taxable from "../components/AllServices/Taxable";
import InternalAudit from "../components/AllServices/InternalAudit";
import ServiceTaxAudit from "../components/AllServices/ServiceTaxAudit";
import TaxDueDiligence from "../components/AllServices/TaxDueDiligence";
import RegisterForeignCompanies from "../components/AllServices/RegisterForeignCompanies";
import OurTeam from "../components/AboutUs/OurTeam";
import Taxation from "../components/ExploreOurServices/Taxation";
import NewBusiness from "../components/ExploreOurServices/NewBusiness";
import Advisory from "../components/ExploreOurServices/Advisory";
import Outsourcing from "../components/ExploreOurServices/Outsourcing";
import TransferPricing from "../components/ExploreOurServices/TransferPricing";
import AuditingSer from "../components/ExploreOurServices/AuditingSer";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/services/direct-taxation",
    element: <DirectTax />,
  },
  {
    path: "/services/indirect-taxation",
    element: <IndirectTaxation />,
  },
  {
    path: "/services/taxable-income-of-expatriates",
    element: <Taxable />,
  },
  {
    path: "/services/internal-audit",
    element: <InternalAudit />,
  },
  {
    path: "/services/service-tax-audit",
    element: <ServiceTaxAudit />,
  },
  {
    path: "/services/tax-due-diligence",
    element: <TaxDueDiligence />,
  },

  {
    path: "/services/registration-of-foreign-companies",
    element: <RegisterForeignCompanies />,
  },
  {
    path: "/gallery",
    element: <Gallery />,
  },
  {
    path: "/contact",
    element: <ContactUs />,
  },
  {
    path: "/about/our-team",
    element: <OurTeam />,
  },
  {
    path: "/services",
    element: <Services />,
  },
  {
    path: "/taxation",
    element: <Taxation />,
  },
  {
    path: "/setting-up-new-business",
    element: <NewBusiness />,
  },
  {
    path: "/advisory",
    element: <Advisory />,
  },
  {
    path: "/outsourcing",
    element: <Outsourcing />,
  },
  {
    path: "/auditing",
    element: <AuditingSer />,
  },
  {
    path: "/transfer-pricing",
    element: <TransferPricing />,
  },
]);
