import React from "react";
import { VscTools } from "react-icons/vsc";
import { AiOutlineBulb } from "react-icons/ai";
import { BsClock } from "react-icons/bs";
import { FaReact } from "react-icons/fa";

const WhyUs = () => {
  const details = [
    {
      id: "1",
      icon: VscTools,
      heading: "Our Vision",
      description:
        "Our firm continuously strives to be the Premier Accounting and Consultancy firm that provides excellent service to our clients and an excellent quality of life for our associates.",
    },
    {
      id: "2",
      icon: AiOutlineBulb,
      heading: "Our Mission",
      description:
        "We will predominantly work with organisations in the charitable and voluntary sectors as partners to help them achieve their desired outcomes.",
    },
    {
      id: "3",
      icon: BsClock,
      heading: "Our Objective",
      description:
        "We are committed to creating and sustaining long-term relationships which drawn on our experience and expertise to help our clients achieve real success.",
    },
    {
      id: "4",
      icon: FaReact,
      heading: "Excellence in Service",
      description:
        "To not just meet but exceed client expectation consistently by imbibing Teamwork, Professionalism, Personalised Service & Specialisation.",
    },
  ];

  return (
    <>
      <div className="py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="p-4 grid gap-5 row-gap-5 sm:grid-cols-2 lg:grid-cols-4 overflow-hidden">
          {details.map((detail, index) => {
            return (
              <div className="px-12 text-center sm:px-4 transition hover:border-blue hover:shadow-[#b6d0fa] hover:scale-105 hover:shadow-2xl" key={index}>
                <div className="flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full bg-blue sm:w-32 sm:h-32">
                  <detail.icon className="w-8 h-8 text-[#fff] sm:w-16 sm:h-16"></detail.icon>
                </div>
                <h6 className="mb-2 text-sm font-bold leading-5 tracking-wider uppercase overflow-hidden">
                  {detail.heading}
                </h6>
                <div className="mb-2 text-gray-700 overflow-hidden">
                  {detail.description}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default WhyUs;
