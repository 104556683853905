import Image from "../Images/gallery/Image.jfif";
import ImageOne from "../Images/gallery/ImageOne.jpeg";
import ImageTwo from "../Images/gallery/ImageTwo.jfif";
import ImageThree from "../Images/gallery/ImageThree.jfif";
import ImageFour from "../Images/gallery/ImageFour.jpeg";
import ImageFive from "../Images/gallery/ImageFive.jfif";
import ImageSix from "../Images/gallery/ImageSix.jpeg";
import ImageSeven from "../Images/gallery/ImageSeven.jpeg";

export const sliderData = [
  {
    image: Image,
    // caption: `San Francisco<br/>Next line`
  },
  {
    image: ImageOne,
    // caption: "Scotland"
  },
  {
    image: ImageTwo,
    // caption: "Darjeeling"
  },
  {
    image: ImageThree,
    // caption: "San Francisco"
  },
  {
    image: ImageFour,
    // caption: "Scotland"
  },
  {
    image: ImageFive,
    // caption: "Darjeeling"
  },
  {
    image: ImageSix,
    // caption: "San Francisco"
  },
  {
    image: ImageSeven,
    // caption: "Scotland"
  },
  // {
  //   image: "https://www.oyorooms.com/travel-guide/wp-content/uploads/2019/02/summer-7.jpg",
  //   caption: "Darjeeling"
  // }
];

export const captionStyle = {
  fontSize: "2em",
  fontWeight: "bold",
};
export const slideNumberStyle = {
  fontSize: "20px",
  fontWeight: "bold",
};
