import React from 'react';
import { generateRandomColor } from '../services/generateRandomColor';
import ourServicesData from '../../constants/ourServicesData';

const GridComponent = () => {
  return (
    <div className="content-gridd">
      <h2>Our Services</h2>
      <div className="grid-container">
        {
          ourServicesData.map((element, index) => {
            var randomColor = generateRandomColor(0.85);
            return (
              <div className="grid-item" key={index}>
                <div className="iconServices" style={{ backgroundColor: randomColor }} >
                  {element.icon}
                </div>
                <p>{element.title}</p>
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

export default GridComponent;
