import React, { useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "@emailjs/browser";
import Footer from "../components/Global/Footer";
import Sidebar from "../components/Global/Sidebaar";
import AdressIcon from "../Images/gallery/AdressIcon.png";

function ContactUs() {
  const form = useRef();
  const emailInput = useRef();
  const messageInput = useRef();
  const [btnText, setBtnText] = useState('Send Message')

  const sendEmail = (e) => {
    e.preventDefault();
    setBtnText('Sending...');
    emailjs
      .sendForm(
        "service_c6dlsfo",
        "template_114vnug",
        form.current,
        "XXv0PWJCeAIhHaCe4"
      )
      .then(
        (result) => {
          toast.success("Your message has been sent successfully!", {
            position: "top-center",
            autoClose: 2000,
            theme: "light",
          });
          form.current.reset();
          setBtnText('Send Message');
        },
        (error) => {
          alert('Failed to send message...');
          setBtnText('Send Message');
        }
      );
  };

  return (
    <>
      <Sidebar />
      <div className="contact-row">
        <div id="addressContainer">
          <div className="officetitle">
            <h1 className="branchheading">
              BRANCH LOCATIONS
              <img src={AdressIcon} alt="addressicon" />
            </h1>
          </div>

          <div className="address">
            <div className="divMumbaiOfficeTitle">
              <h2 className="mumbaiOfficeTitle">HEAD OFFICE MUMBAI</h2>
            </div>

            <div className="divmumbaiaddress">
              <p className="mumbaiaddress">
                296/2353, Motilal Nagar No. 2, Near Manthan Hotel, M.G Road,
                Goregaon West, Mumbai - 400104.
              </p>

              <p className="contactno">
                <span className="phoneno">Contact No :</span> +91 9821518350 /
                +91 9821054140
              </p>
            </div>
          </div>

          <div className="address">
            <div className="divMumbaiOfficeTitle">
              <h2 className="mumbaiOfficeTitle">CANADA</h2>
            </div>

            <div className="divmumbaiaddress">
              <p className="mumbaiaddress">
                Unit 1634, 33 Harbour Square, Toronto, ON, M5J 2G2.
              </p>

              <p className="contactno">
                <span className="phoneno">Contact No :</span> +1 6478187955
              </p>
            </div>
          </div>

          <div className="address">
            <div className="divMumbaiOfficeTitle">
              <h2 className="mumbaiOfficeTitle">DUBAI</h2>
            </div>

            <div className="divmumbaiaddress">
              <p className="mumbaiaddress">
                Bur Dubai, Sheikh Zeyad Road, Dubai.
              </p>
              <p className="contactno">
                <span className="phoneno">Contact No :</span> +971 507837141
              </p>
            </div>
          </div>
        </div>

        <div className="maindivform">
          <div className="from-container">
            <form ref={form} onSubmit={sendEmail}>
              <h1 className="sendMsgHead">CONTACT US</h1>
              <input
                type="text"
                name="user_name"
                className="Input"
                placeholder="Name"
              />
              <input
                type="email"
                name="user_email"
                className="Input"
                placeholder="Email"
                ref={emailInput}
                required
              />
              <input
                type="text"
                name="user_subject"
                className="Input"
                placeholder="Subject"
              />
              <textarea
                name="message" // add a name attribute to identify the textarea
                className="Input-message"
                placeholder="Message"
                rows={4}
                ref={messageInput}
                required
              // bind the value to formState.message
              // add onChange handler to update formState.message
              ></textarea>
              <div className="buttondiv">
                <button value="Send" className="sendmsgButton">
                  {btnText}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
      <Footer />
    </>
  );
}

export default ContactUs;
