import React from "react";
import Footer from "../Global/Footer";
import Sidebar from "../Global/Sidebaar";

import ServicesRedirectButtons from "../ServicesAllTabs/ServicesRedirectButtons";

const IndirectTaxation = () => {
  return (
    <>
      <Sidebar />
      <div id="main-div-direct-tax">
        <div className="sub-div-direct-tax">
          <ServicesRedirectButtons />
          <div className="main-div-direct-tax-content">
            <div className="indirect-taxation">
            <h1>INDIRECT TAXATION</h1>
            </div>
            <h2>SERVICE TAX</h2>
            <p>
              Service tax is a central tax, which has been imposed on certain
              services and is the latest addition to the genus of indirect taxes
              like customs and central excise duty. India, a developing country,
              was somewhat slow in discovering the potential of this kind of
              indirect taxation for enhancement of revenue collection and it was
              the Finance Act 1994 that first introduced the service tax
              provisions through its Chapter V. Service Tax is collected by
              Central Excise Department.
            </p>

            <h2>Some of our services include :</h2>

            <ul className="bullet-points">
              <li>
                <p>
                  {"\u2022"} Compiling and calculating the net service tax on
                  output services after taking benefit of Cenvat Credits.
                </p>
              </li>
              <li>
                <p>
                  {"\u2022"} Compiling the data of Cenvat Credits for service
                  tax.
                </p>
              </li>
              <li>
                <p>{"\u2022"} Preparing & Filing of Service tax Returns.</p>
              </li>
              <li>
                <p>
                  {"\u2022"} Advising on the issues relating to Service tax.
                </p>
              </li>
              <li>
                <p>
                  {"\u2022"} Consultancy on the maintenance of prescribed
                  records.
                </p>
              </li>
              <li>
                <p>
                  {"\u2022"} Tax Planning as regards the minimization of Service
                  Tax Liability.
                </p>
              </li>
            </ul>

            <h2 className="valueAddHead">VALUE ADDED TAX (VAT)</h2>
            <p>
              “Value Added Tax” (VAT) is a tax on value addition and a multi
              point tax, which is levied at every stage of sale. It is collected
              at the stage of manufacture/resale and contemplates rebating of
              tax paid on inputs and purchases.
            </p>

            <h2>Some of our VAT related services include :</h2>

            <ul className="bullet-points">
              <li>
                <p>{"\u2022"} Rendering assistance in registration under VAT</p>
              </li>
              <li>
                <p>{"\u2022"} Assistance in claiming input tax credit</p>
              </li>
              <li>
                <p>
                  {"\u2022"} Assistance in furnishing tax returns and claiming
                  refunds
                </p>
              </li>
              <li>
                <p>{"\u2022"} Advice on the legal aspects of VAT</p>
              </li>
              <li>
                <p>
                  {"\u2022"} Rendering advice on the wide range of issues in
                  relation to tax invoices and retails invoices
                </p>
              </li>
              <li>
                <p>{"\u2022"} Internal Audit and Compliance Reviews</p>
              </li>
              <li>
                <p>
                  {"\u2022"} Helping with audit of accounts necessary for a
                  registered dealer
                </p>
              </li>
            </ul>
            <h2 className="valueAddHead">EXCISE DUTY</h2>
            <p>
            Excise duty is an indirect tax levied by governments on the production, sale, or use of specific goods, such as alcohol, tobacco, and petroleum products. It aims to generate revenue for the government and discourage the consumption of goods with known negative externalities.
            </p>

            <h2>Some of our Excise duty related services include :</h2>

            <ul className="bullet-points">
              <li>
                <p>{"\u2022"} Excise Duty Planning</p>
              </li>
              <li>
                <p>{"\u2022"} Assistance and monitoring of Excise Audit by External Cost Accountants</p>
              </li>
              <li>
                <p>
                  {"\u2022"} Preparation of Cost Accounting Records in term of Cost Accounting (Records) Rules
                </p>
              </li>
              <li>
                <p>{"\u2022"} Internal Audit of Excise Records and assuring management of correctness of payments to Excise Authorities.</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default IndirectTaxation;
