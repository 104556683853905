import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const ServicesRedirectButtons = () => {
  const [activeButton, setActiveButton] = useState("direct-taxation");
  const location = useLocation();

  useEffect(() => {
    setActiveButton(location.pathname.split("/").pop());
  }, [location]);

  return (
    <div className="tabContainer">
      <ul className="tabContainer__list">
        <li
          style={{
            backgroundColor:
              activeButton === "direct-taxation" ? "#142886" : "white",
            border: "2px solid white",
          }}
        >
          <Link to="/services/direct-taxation">
            <button
              style={{
                backgroundColor: "transparent",
                color: activeButton === "direct-taxation" ? "white" : "",
                width: "100%",
                textAlign: "start",
              }}
              onClick={() => setActiveButton("direct-taxation")}
            >
              DIRECT TAXATION
            </button>
          </Link>
        </li>
        <li
          style={{
            backgroundColor:
              activeButton === "indirect-taxation" ? "#142886" : "white",
            border: "2px solid white",
          }}
        >
          <Link to="/services/indirect-taxation">
            <button
              style={{
                backgroundColor: "transparent",
                color: activeButton === "indirect-taxation" ? "white" : "",
                width: "100%",
                textAlign: "start",
              }}
              onClick={() => setActiveButton("indirect-taxation")}
            >
              INDIRECT TAXATION
            </button>
          </Link>
        </li>

        <li
          style={{
            backgroundColor:
              activeButton === "taxable-income-of-expatriates"
                ? "#142886"
                : "white",
            border: "2px solid white",
          }}
        >
          <Link to="/services/taxable-income-of-expatriates">
            <button
              style={{
                backgroundColor: "transparent",
                color:
                  activeButton === "taxable-income-of-expatriates"
                    ? "white"
                    : "",
                width: "100%",
                textAlign: "start",
              }}
              onClick={() => setActiveButton("taxable-income-of-expatriates")}
            >
              TAXABLE INCOME
            </button>
          </Link>
        </li>

        <li
          style={{
            backgroundColor:
              activeButton === "internal-audit" ? "#142886" : "white",
            border: "2px solid white",
          }}
        >
          <Link to="/services/internal-audit">
            <button
              style={{
                backgroundColor: "transparent",
                color: activeButton === "internal-audit" ? "white" : "",
                width: "100%",
                textAlign: "start",
              }}
              onClick={() => setActiveButton("internal-audit")}
            >
              INTERNAL AUDIT
            </button>
          </Link>
        </li>

        <li
          style={{
            backgroundColor:
              activeButton === "service-tax-audit" ? "#142886" : "white",
            border: "2px solid white",
          }}
        >
          <Link to="/services/service-tax-audit">
            <button
              style={{
                backgroundColor: "transparent",
                color: activeButton === "service-tax-audit" ? "white" : "",
                width: "100%",
                textAlign: "start",
              }}
              onClick={() => setActiveButton("service-tax-audit")}
            >
              SERVICE TAX AUDIT
            </button>
          </Link>
        </li>

        <li
          style={{
            backgroundColor:
              activeButton === "tax-due-diligence" ? "#142886" : "white",
            border: "2px solid white",
          }}
        >
          <Link to="/services/tax-due-diligence">
            <button
              style={{
                backgroundColor: "transparent",

                color: activeButton === "tax-due-diligence" ? "white" : "",
                width: "100%",
                textAlign: "start",
              }}
              onClick={() => setActiveButton("tax-due-diligence")}
            >
              TAX DUE DILIGENCE
            </button>
          </Link>
        </li>

        <li
          style={{
            backgroundColor:
              activeButton === "registration-of-foreign-companies"
                ? "#142886"
                : "white",
            border: "2px solid white",
          }}
        >
          <Link to="/services/registration-of-foreign-companies">
            <button
              style={{
                backgroundColor: "transparent",
                color:
                  activeButton === "registration-of-foreign-companies"
                    ? "white"
                    : "",
                width: "100%",
                textAlign: "start",
              }}
              onClick={() =>
                setActiveButton("registration-of-foreign-companies")
              }
            >
              REGISTRATION OF FOREIGN COMPANIES
            </button>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default ServicesRedirectButtons;
