import React from "react";
import { BsGearFill } from "react-icons/bs";
import { generateRandomColor } from '../services/generateRandomColor';

const Services = () => {
  const services = [
    {
      id: 1,
      title: "Company Formation in India",
      description:
        "We give advice on Company Registration in India and provide the complete solution for Company Formation in India, Private limited Company Formation and Company Incorporation in India.",
    },
    {
      id: 2,
      title: "Starting Business in India",
      description:
        "Business can be setup in India in three types like Formation of liaison office, Formation of Project office and Formation of Branch office",
    },
    {
      id: 3,
      title: "Taxation of Expatriates",
      description:
        "The employment of expatriates and foreign nationals involves several complex issues. Whilst the Indian economy and market place has generally been less attractive to expatriates and foreign workers.",
    },
    {
      id: 4,
      title: "Foreign Investment Approvals",
      description:
        "Foreign investment in India is primarily governed by the FDI policy formulated by the secretariat for industrial assistance (SIA), the Department of Industrial policy and promotion (DIPP).",
    },
    {
      id: 5,
      title: "Corporate Compliance",
      description:
        "Indian companies are governed by Companies Act 1956 and company has to comply with various statutory provisions as per different sections of Companies Act 1956.",
    },
    {
      id: 6,
      title: "Accounts Outsourcing",
      description:
        "A refinance allows you to take out new personal loans that pay off your current mortgage. Although you are then obligated to make payments on the new top up card loan, your costs typically are lower after refinancing.",
    },
    {
      id: 7,
      title: "Auditing",
      description:
        "Internal Audit is an independent appraisal function established within an organization to examine and evaluate its activities as a service to the organization.",
    },
    {
      id: 8,
      title: "Business Taxation",
      description:
        "Direct tax consultancy together with innovative tax efficient strategies, provided by us form an integral part of viable business decisions. These help our clients attain the desired goals.",
    },
    {
      id: 9,
      title: "Transfer Pricing",
      description:
        "Since 1991, with the liberalization of trade and foreign exchange policy India has started integrating its economy with global economy. This has led to increased cross border flow of goods, services, funds and even intangibles.",
    },
  ];

  return (
    <>
      <section class="text-black">
        <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
          <div class="mx-auto max-w-lg text-center">
            <h2 class="text-3xl font-bold sm:text-4xl overflow-hidden">Services Offering</h2>

            <p class="mt-4 text-black overflow-hidden">
              We at NBC focus on helping clients design and build Tomorrow’s organisation. We provide real world solutions to complex business issues through audit and assurance functions, taxation-international and domestic, startup in India, company formation in India and foreign investment in India etc.
            </p>
          </div>

          <div class="mt-8 p-3 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 overflow-hidden">
            {services.map((service, index) => {
              var randomTopColor = generateRandomColor(0.55);
              return (
                <a
                  class="block rounded-xl border border-black  transition hover:border-blue hover:shadow-[#b6d0fa] hover:scale-105 shadow-md"
                  href="/services/digital-campaigns"
                >
                  <div
                    style={{
                      display: "block",
                      backgroundColor : randomTopColor,
                      height: "20px",
                      width: "100%",
                      margin: "0px",
                    }}
                  ></div>

                  <BsGearFill
                    size={40}
                    style={{
                      margin: "16px 24px",
                    }}
                    className="text-[] "
                  ></BsGearFill>

                  <h2 class="mt-4 px-8 py-2 text-xl font-bold text-lightBlack overflow-hidden">
                    {service.title}
                  </h2>

                  <p class="mt-1 px-8 py-4 text-sm text-black overflow-hidden">
                    {service.description}
                  </p>
                </a>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
