import React from 'react';
import Sidebar from '../Global/Sidebaar';
import Footer from '../Global/Footer';

const NewBusiness = () => {
    return (
        <div>
            <>
                <Sidebar />
                <div>
                    <div className='taxation-main-head'>
                        <h2>Setting Up New Business</h2>
                        <div className='content-comm'>
                            <p>The process of selecting the form of business organization plays a very important role in setting up of business, as various factors like flexibility, business requirement, taxation etc are required to be considered and a wrong decision may hamper your future business plan. As its long term plan which need to be rationalized based on the pros and cons on the type of organization.
                            </p>
                        </div>
                        <div>
                            <p>
                                At Shaikh & Associate, we provide option to choose the right kind of business form and therefore we can help you in understanding the technicalities and positives that lies behind each form, to enable you to take a right and informed business setup decision.
                            </p>
                        </div>
                    </div>

                </div>
                <Footer />
            </>
        </div>
    )
}

export default NewBusiness