import React from "react";

const Help = () => {
  return (
    <>
      <div class="inset-x-0 bottom-0">
        <div class="relative max-w-full bg-[#444343] px-4 py-3">
          <p class="text-left text-sm font-medium sm:text-center text-[#fff] overflow-hidden">
            Have a question? We can help you... Call +91 9821518350
          </p>
        </div>
      </div>
    </>
  );
};

export default Help;
