import React from "react";
import Slider from "react-slick";
import { sliderData } from "../../constants/sliderConstant";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../CSS/sliderImage.css";

const ImgSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3500,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      <Slider {...settings}>
        {sliderData.map((slide, index) => (
          <div className="img-container" key={index}>
            <img src={slide.image} alt={`Slide ${index}`} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImgSlider;
