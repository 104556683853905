// import Header from "./components/Header/Header";
import ImgSlider from "./components/HomeSection/ImgSlider";
import Services from "./components/services/Services";
// import NewsLetter from "./components/NewsLetter";
import WhyUs from "./components/HomeSection/WhyUs";
import Help from "./components/Global/Help";
import Contact from "./components/HomeSection/Contact";
import Questions from "./components/HomeSection/Questions.jsx";
import Sidebaar from "./components/Global/Sidebaar";
import Footer from "./components/Global/Footer";
import GridComponent from "./components/HomeSection/GridServices.jsx";
// import GridServices from "./components/HomeSection/GridExpServices.jsx";

function App() {
  return (
    <div className="App">
      {/* <Header /> */}
      <Sidebaar />
      <ImgSlider />
      {/* <NewsLetter /> */}
      <WhyUs />
      <GridComponent />
      <Services />
      {/* <GridServices /> */}
      <Questions />
      <Contact />
      <Help />
      <Footer />
    </div>
  );
}

export default App;
