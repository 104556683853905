import React, { useState } from "react";

import { GrClose } from "react-icons/gr";

import { images } from "../data/GalleryData";

import "../CSS/Gallery.css";
import Sidebar from "../components/Global/Sidebaar";
import Footer from "../components/Global/Footer";

const Gallery = () => {
  const [modal, setModal] = useState(false);
  const [tempSrc, setTempSrc] = useState("");
  const getImage = (imgSrc) => {
    setTempSrc(imgSrc);
    setModal(true);
  };
  return (
    <>
      {/* <Navbar /> */}
      <Sidebar />
      <h1 className="text-4xl font-semibold text-center mt-8 mb-3">
        Our Gallery
      </h1>
      <div className={modal ? "modal open" : "modal"}>
        <img src={tempSrc} alt="" />
        <GrClose onClick={() => setModal(false)} />
      </div>
      <div className="gallery">
        {images.map((image, index) => {
          return (
            <>
              <div
                className="pics"
                key={index}
                onClick={() => getImage(image.src)}
              >
                <img src={image.src} alt={image.alt} className="w-full" />
              </div>
            </>
          );
        })}
      </div>
      <Footer />
    </>
  );
};

export default Gallery;
