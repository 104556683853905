import React from "react";
import Footer from "../Global/Footer";
import Sidebar from "../Global/Sidebaar";

import ServicesRedirectButtons from "../ServicesAllTabs/ServicesRedirectButtons";

const Taxable = () => {
  return (
    <>
      <Sidebar />
      <div id="main-div-taxable">
        <div className="sub-div-taxable">
          <ServicesRedirectButtons />

          <div className="sub-div-taxable-content">
            <div className="taxable-income">
            <h1>TAXABLE INCOME OF EXPATRIATES</h1>
            </div>
            <p>
              <strong> Normally, a foreign citizen</strong> who visits India and
              renders services incurs tax liability on the income earned in
              India. When an taxation expatriates services works in India, their
              entire salary-related income is subject to tax, even if it is paid
              outside India.
            </p>
            <p>
              Expenses incurred by an employer in moving an expatriate to and
              from India and home-leave airfares are not considered taxable
              income in India. Similarly, payments made in an expatriate’s home
              country as retirements benefits are also not considered as income
              earned in India.
            </p>
            <p>
              For tax purposes, salary includes base salary, cost-of-living
              allowances, bonuses, ex-gratia, reimbursement of school fees,
              utilities, house rent, transportation, tax reimbursements and
              other payments the company makes on behalf of the employee.
              Certain in-kind benefits, such as company-lased accommodations,
              security guards, provision of car with driver, are all taxable on
              a concessional basis.
            </p>

            <h2>Special provisions for foreigners</h2>
            <p>
              Foreigners are entitled to certain special concessions as follows:
            </p>
            <p>
              (1) Remuneration received by a foreigner as an employee of a
              foreign enterprise for services rendered in India is not subject
              to Indian income tax, provided:
            </p>

            <ul>
              <li>
                <p>
                  {"\u2022"} the foreign enterprise is not engaged in any trade
                  or business in India;
                </p>
              </li>
              <li>
                <p>
                  {"\u2022"} the foreign enterprise is not engaged in any trade
                  or business in India;
                </p>
              </li>
              <li>
                <p>
                  {"\u2022"} the foreign enterprise is not engaged in any trade
                  or business in India;
                </p>
              </li>
            </ul>
            <p>
              (2) A foreigner ( including a non-resident Indian) who was not
              resident in India in any of the four financial years immediately
              preceding the year of arrival in India is entitled to a special
              tax concession, if provided:
            </p>

            <ul>
              <li>
                <p>
                  {"\u2022"} the foreigner has specialized knowledge and
                  experience in certain specified industries; a and
                </p>
              </li>
              <li>
                <p>
                  {"\u2022"} the individual is employed in any business in India
                  in a capacity in which specialized knowledge and experience
                  are used.
                </p>
              </li>
            </ul>

            <p>
              (3) A visiting foreign professor who teaches in any university or
              educational institution in India is exempt from tax on
              remuneration received during the first 36 months from the date of
              arrival in India. However the individual should have not been a
              resident in India in any of the four financial years immediately
              preceding the year of arrival in India.
            </p>
            <p>
              (4) Salary received by a non-resident foreigner in connection with
              employment on a foreign ship is exempt from tax if the employee’s
              stay in India during a year does not exceed 90 days.
            </p>
            <p>
              (5) Special exemptions under specified circumstances are available
              for the following:
            </p>

            <ul>
              <li>
                <p>
                  {"\u2022"} amounts receivable from a foreign government or a
                  foreign body by a foreigner for undertaking research in India
                  under an approved scheme;
                </p>
              </li>
              <li>
                <p>
                  {"\u2022"} remuneration received by employees of a foreign
                  government during training with the Indian government or in an
                  Indian government undertaking; and
                </p>
              </li>
              <li>
                <p>
                  remuneration received by non-resident expatriates in
                  connection with the filing of motion pictures by non-resident
                  producers.
                </p>
              </li>
            </ul>

            <h2>Statutory deductions</h2>
            <p>
              Under the provisions of the Income Tax Act 1961 every person
              responsible for paying salary, shall at the time of payment,
              deduct income tax on the amount payable at the average rate of tax
              computed on the basis of the rates in force for that particular
              financial year.
            </p>
            <p>
              This statutory deduction will be made even if the foreigner is
              simultaneously under more than one employer, or has had successive
              employment under more than one of them.
            </p>
            <p>
              Other deductions from the income of the foreign national include,
              deductions:
            </p>

            <ul>
              <li>
                <p>{"\u2022"} for recovery of advances of whatever nature;</p>
              </li>
              <li>
                <p>
                  {"\u2022"} for recovery of loans made from any fund
                  constituted for the welfare of labour;
                </p>
              </li>
              <li>
                <p>
                  {"\u2022"} for damage to or loss of goods entrusted for
                  custody;
                </p>
              </li>
              <li>
                <p>
                  {"\u2022"} for house accommodation supplied by the employer;
                </p>
              </li>
              <li>
                <p>{"\u2022"} required to be made by order of a court; and</p>
              </li>
              <li>
                <p>
                  {"\u2022"} for subscriptions to and for repayment of, advances
                  from any provident fund to which the provident fund applies
                  etc.
                </p>
              </li>
            </ul>

            <h2>Expatriate remuneration</h2>
            <p>
              Asia has gone from being largely labour intensive to highly a
              technologically oriented market. It is a great business
              opportunity for global companies. But more than that, it calls for
              meticulous attention to expatriate taxation planning. HR
              practitioners in India, particularly compensation and benefits
              specialists, would find it useful to understand remuneration
              practices followed in the rest of the Asian region.
            </p>
            <p>
              The remuneration package of the expatriate should normally include
              base salary, cost-of-living allowances, bonuses, ex-gratia,
              reimbursement of school fees, health insurance, medical
              reimbursement, utilities, house rent, transportation, tax
              reimbursements and other payments the company makes on behalf of
              the employee. Certain in-kind benefits, such as company-leased
              accommodations, security guards, provision of car with driver, are
              generally provided by the employer.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Taxable;
